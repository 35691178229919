import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'

import {PersonModel} from '@api/models'
import {changePersonArticlesSort} from '@api/requests'

import {useAuth} from '@modules/auth'

import {useArticleStore} from '@store/articles'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {useEditArticleDrawerStore} from '@_metronic/partials/layout/editarticle-drawer/EditArticleDrawer'

import SimDrawerArticle from '@components/atoms/SimDrawerArticle'

type SimDrawerArticlesProps = {
  personId: PersonModel['id']
  readonly: boolean
  allowSharing?: boolean
}

const SimDrawerArticles = ({personId, readonly, allowSharing}: SimDrawerArticlesProps) => {
  const intl = useIntl()

  const {activePersonArticles, setSortForArticles} = useArticleStore()
  const {open: showEditArticle} = useEditArticleDrawerStore()

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const handleAddPress = (event) => {
    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      event.preventDefault()
      event.stopPropagation()

      return
    }

    showEditArticle({personId})
  }

  const sortedArticles = useMemo(
    () =>
      activePersonArticles?.sort((a, b) => {
        if (a.sort === undefined) return 0
        if (b.sort === undefined) return 0
        return a.sort - b.sort
      }),
    /*activePersonArticles?.sort(({createdAt: createdAtA}, {createdAt: createdAtB}) =>
            moment(createdAtA).isBefore(createdAtB) ? 1 : -1
        ),*/
    [activePersonArticles]
  )
  const upArticle = (key) => {
    let articles = activePersonArticles
    if (key > 0) {
      let prevArticle = articles[key - 1]
      articles[key - 1] = articles[key]
      articles[key] = prevArticle
      articles = articles.map((article, k) => {
        return {
          ...article,
          sort: k,
        }
      })
      let arrOfSortedArticles = articles.map((x) => {
        return {
          _id: x._id,
          sort: x.sort,
        }
      })

      setSortForArticles(arrOfSortedArticles) //AND SEND TO API
      changePersonArticlesSort(personId, arrOfSortedArticles)
    }
  }

  const downArticle = (key) => {
    let articles = activePersonArticles
    if (key < activePersonArticles.length - 1) {
      let nextArticle = articles[key + 1]
      articles[key + 1] = articles[key]
      articles[key] = nextArticle
      articles.map((article, k) => {
        return {
          ...article,
          sort: k,
        }
      })
      let arrOfSortedArticles = articles.map((x) => {
        return {
          _id: x._id,
          sort: x.sort,
        }
      })

      setSortForArticles(arrOfSortedArticles) //AND SEND TO API
      changePersonArticlesSort(personId, arrOfSortedArticles)
    }
  }
  return (
    <div className='tab-pane fade active show' id='kt_tab_pane_2' role='tabpanel'>
      <div className='card-header p-0 mb-4'>
        <h3 className='card-title align-items-start flex-column mt-0 pt-0'>
          <span className='card-label fw-bold text-gray-900'>
            {intl.formatMessage({id: 'SIM.VIEW.TAB_ARTICLES'})}
          </span>
        </h3>

        {!readonly ? (
          <div className='card-toolbar'>
            <a className='btn btn-sm btn-light' onClick={handleAddPress}>
              {intl.formatMessage({id: 'PERSON.ADD'})}
            </a>
          </div>
        ) : null}
      </div>
      {sortedArticles?.map((article, index, arr) => (
        <SimDrawerArticle
          key={article._id}
          first={index === 0}
          last={index === arr.length - 1}
          upArticle={() => upArticle(index)}
          downArticle={() => downArticle(index)}
          maxLength={arr.length}
          personId={personId}
          article={article}
          readonly={readonly}
          allowSharing={allowSharing}
        />
      ))}
    </div>
  )
}

export {SimDrawerArticles}
