import React from 'react'
import styled from 'styled-components'

import usePrepareDataForTree from './hooks/usePrepareDataForTree'

import TreeCanvas from './components/TreeCanvas'

import {TREE_BACKGROUND_COLORS, TreeBackgroundColorsEnum} from '@constants/treeBackgroundColors'

const Tree = ({editMode, treeId, tree, persons, readonly = false, initialized = true}) => {
  const {nodes, personsMap} = usePrepareDataForTree(tree.nodes, persons)

  const background =
    tree.background?.url ||
    (tree.background_id < 0
      ? TREE_BACKGROUND_COLORS[tree.background_id].image
      : TREE_BACKGROUND_COLORS[TreeBackgroundColorsEnum.green].image)

  // console.log('### background', background)
  // console.log('### nodes?.length', nodes?.length)
  // console.log('### Tree.readonly', readonly)

  return (
    <Wrapper $background={background} $readonly={readonly}>
      {nodes?.length > 0 ? (
        <TreeCanvas
          treeId={treeId}
          nodes={nodes}
          personsMap={personsMap}
          editMode={editMode}
          initialized={initialized}
          readonly={readonly}
        />
      ) : null}
    </Wrapper>
  )
}

export default React.memo(Tree)

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${({$background}) => $background});
  background-size: cover;
  background-position: center;
  border-radius: ${({$readonly}) => ($readonly ? 0 : 5)}px;

  overflow: hidden;
`
