/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useIntl} from 'react-intl'

import {getUserByToken, register, RegistrationBody, googleRedirect} from '../core/_requests'
import {registrationSchema} from '../validation-schemas/registration-schema'
import {toAbsoluteUrl} from '@_metronic/helpers'

import {useAuth} from '../core/Auth'
import useReferralString from '../../../hooks/useReferralString'

import {PasswordMeterComponent} from '@_metronic/assets/ts/components'
import FormDivider from '@app/components/atoms/FormDivider'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

export function Registration() {
  const intl = useIntl()
  const currentLocale = intl.locale

  const ref_token = useReferralString()

  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema(currentLocale),
    validateOnChange: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)

      try {
        const registrationData: RegistrationBody = {
          email: values.email,
          first_name: values.firstname.trim(),
          last_name: values.lastname.trim(),
          password: values.password,
        }

        if (ref_token) {
          registrationData.ref_token = ref_token
        }

        const {data: auth} = await register(registrationData)
        saveAuth(auth)
        const {data} = await getUserByToken(auth.TOKEN)
        setCurrentUser(data)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const getGoogleRedirect = async (refToken: string | null) => {
    return refToken ? googleRedirect(refToken) : googleRedirect()
  }

  const handleGoogleLogin = async () => {
    try {
      const {data} = await getGoogleRedirect(ref_token)
      window.location.href = data.value
    } catch (err) {
      console.error('Error during Google login: ', err)
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}
        </h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      <div className='d-grid mb-4'>
        <button
          type='button'
          id='kt_sign_in_google'
          className='btn border border-success text-success my-3 w-100'
          onClick={handleGoogleLogin}
        >
          {!loading && (
            <div className='d-flex align-items-center justify-content-center'>
              <img
                alt='Google Logo'
                src={toAbsoluteUrl('/media/logos/google.svg')}
                style={{height: '12px'}}
              />
              <span className='indicator-label ms-2'>
                {intl.formatMessage({id: 'AUTH.GOOGLE'})}
              </span>{' '}
            </div>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2' />
            </span>
          )}
        </button>
      </div>

      <FormDivider text={intl.formatMessage({id: 'AUTH.WITH_EMAIL'})} />

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.FIRST_NAME'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.FIRST_NAME'})}
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.EMAIL'})}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>{intl.formatMessage({id: 'AUTH.VALIDATION.PASSWORD'})}</div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            {intl.formatMessage({id: 'AUTH.REGISTER.ACCEPT_TERMS'})}
            <a
              href='https://thesimstree.com/politika-konfidenczialnosti.html'
              target='_blank'
              className='ms-1 link-primary'
            >
              {intl.formatMessage({id: 'AUTH.REGISTER.TERMS'})}
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-success w-100'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && (
            <span className='indicator-label'>{intl.formatMessage({id: 'ACTIONS.SUBMIT'})}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
