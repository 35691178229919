import React from 'react'
import styled from 'styled-components'

import useTreeStyles from '@hooks/useTreeStyles'
import useFirstLetters from '@components/organisms/Tree/hooks/useFirstLetters'
import useHighlightParents from '@components/organisms/Tree/hooks/useHighlightParents'

import {NODE_HEIGHT} from '@components/organisms/Tree/constants'

import {TreeModel} from '@api/models'
import {PetModel} from '@components/organisms/Tree/types'
import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

const PADDING = 2

type PetProps = {size: number; pet: PetModel; treeId: TreeModel['id']; editMode: boolean}
const Pet = ({size, pet, treeId, editMode}: PetProps) => {
  const {show: showSimDrawer} = useSimDrawerStore()
  const handleNodePress = (e) => {
    if (pet.id && !editMode) {
      showSimDrawer(pet.id)
    }
  }

  const {
    avatarBorderColor,
    avatarBorderWidth = 2,
    avatarBorderRadius,
    avatarBorderType,
  } = useTreeStyles(treeId)
  const avatarBorderWidthParsed = parseInt(`${avatarBorderWidth}`)
  const avatarBorderRadiusParsed = (size / NODE_HEIGHT) * parseInt(`${avatarBorderRadius}`)

  const firstLetters = useFirstLetters(pet.name)
  const clickable = !editMode && typeof pet.id !== 'undefined'
  const highlight = useHighlightParents(pet, false, editMode)

  // const debugColor = Math.floor(Math.random() * 16777215).toString(16)
  const avatarUrl = useHostBasedUrl(pet.avatar)

  return (
    <Wrapper
      id='kt_sim_toggle'
      $size={size}
      $clickable={clickable}
      $highlight={highlight}
      onClick={handleNodePress}
    >
      <Avatar
        $size={size}
        $avatarBorderColor={avatarBorderColor}
        $avatarBorderWidth={avatarBorderWidthParsed}
        $avatarBorderRadius={avatarBorderRadiusParsed}
        $avatarBorderType={avatarBorderType as string}
      >
        {avatarUrl ? (
          <AvatarBackground src={avatarUrl} />
        ) : firstLetters ? (
          <Initials $size={size}>{firstLetters}</Initials>
        ) : (
          <AvatarBackground src={require('@assets/images/pet-avatar.jpeg')} />
        )}
      </Avatar>
    </Wrapper>
  )
}

export default React.memo(Pet)

const Wrapper = styled.div<{
  $size: number
  $clickable: boolean
  $highlight: boolean
  $debugColor?: string
}>`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  height: ${({$size}) => $size}px;
  width: ${({$size}) => $size}px;

  margin-left: -${({$size}) => $size / 2 - PADDING}px;
  padding: ${PADDING}px;

  cursor: ${({$clickable}) => ($clickable ? 'pointer' : 'initial')};
  user-select: none;

  position: relative;

  opacity: ${({$highlight}) => ($highlight ? 1 : 0.4)};
  transition: opacity 300ms;

  background-color: #${({$debugColor}) => $debugColor};
`

const Avatar = styled.div<{
  $size: number
  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
}>`
  width: ${({$size}) => $size - PADDING * 2}px;
  height: ${({$size}) => $size - PADDING * 2}px;

  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  border-radius: ${({$avatarBorderRadius}) => $avatarBorderRadius / 2}px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

  box-sizing: border-box;

  overflow: hidden;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

const Initials = styled.div<{$size: number}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
  height: 100%;

  font-size: ${({$size}) => $size * 0.3}px;
  color: #fff;
  font-weight: 500;
`
