/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'

import {Logout, AuthPage, useAuth, ConfirmEmail} from '@modules/auth'

import {ErrorsPage} from '@modules/errors/ErrorsPage'
import {PrivateRoutes} from './PrivateRoutes'
import {App} from '../App'
import PublicTree from '@pages/PublicTree'
import PersonSubTree from '@pages/PersonSubTree'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()

  useEffect(() => {
    let userId

    /* window.ym(95973931, 'getClientID', function (clientID) {
      userId = clientID
    })
    if (currentUser) {
      window.ym(95973931, 'userParams', {
        email: currentUser.email,
        first_name: currentUser.first_name,
        id: currentUser.id,
        avatar: currentUser.avatar,
        UserID: userId,
      })
    }*/
  }, [currentUser])

  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          <Route path='confirm' element={<ConfirmEmail />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/crafted/pages/dynasty/sims' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
          <Route path='public/tree/*' element={<PublicTree />} />
          <Route path='person/subtree/*' element={<PersonSubTree />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
