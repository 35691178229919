import React, {useMemo, useState} from 'react'
import {useAuth} from '@modules/auth'
import {useIntl} from 'react-intl'
import {AUTH_LINK, REGISTRATION_LINK} from '@constants/links'

type MessageInputProps = {
  sendMessage: (text: string) => void
  refToken?: string
}
const MessageInput = ({sendMessage, refToken}: MessageInputProps) => {
  const intl = useIntl()
  const {currentUser} = useAuth()

  const [message, setMessage] = useState<string>('')

  const handleSendMessage = () => {
    console.log('### handleSendMessage', `"${message.trim()}"`)
    if (message.trim()) {
      sendMessage(message)
      setMessage('')
    }
  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  const registrationLink = useMemo(
    () => `${REGISTRATION_LINK}${refToken ? `?ref=${refToken}` : ''}`,
    [refToken]
  )

  const authLink = useMemo(() => `${AUTH_LINK}${refToken ? `?ref=${refToken}` : ''}`, [refToken])

  if (currentUser)
    return (
      <>
        <textarea
          className='form-control form-control-flush mb-3 me-5'
          rows={1}
          data-kt-element='input'
          placeholder={intl.formatMessage({id: 'GALLERY.MESSENGER.ENTER_MESSAGE'})}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        />

        <div className='d-flex flex-stack justify-content-end'>
          <button
            className='btn btn-icon btn-success'
            type='button'
            data-kt-element='send'
            disabled={!message.trim()}
            onClick={handleSendMessage}
          >
            <i className='las la-paper-plane fs-2'></i>
          </button>
        </div>
      </>
    )

  return (
    <div className='alert alert-info d-flex align-items-center p-5 mb-10'>
      <div className='d-flex flex-column'>
        <span>{intl.formatMessage({id: 'TREE.CHAT.AUTH_REQUIREMENT'})}</span>
        <div className='d-flex justify-content-center mt-5'>
          <a
            className='btn btn-sm btn-outline btn-outline-dashed btn-outline-danger btn-active-light-danger me-2'
            target='_blank'
            href={registrationLink}
            rel='noreferrer'
          >
            {intl.formatMessage({id: 'AUTH.REGISTER.TITLE'})}
          </a>
          <a
            className='btn btn-sm btn-outline btn-outline-dashed btn-outline-success btn-active-light-success me-2'
            target='_blank'
            href={authLink}
            rel='noreferrer'
          >
            {intl.formatMessage({id: 'AUTH.LOGIN.TITLE'})}
          </a>
        </div>
      </div>
    </div>
  )
}

export default React.memo(MessageInput)
