import React from 'react'
import {setLanguage} from '@_metronic/i18n/Metronici18n'
import {toAbsoluteUrl} from '@_metronic/helpers'
import styled from 'styled-components'

const RU_LANGUAGE = {
  lang: 'ru',
  name: 'Русский',
  flag: toAbsoluteUrl('/media/flags/russia.svg'),
}

type ProbablyRussianWarningProps = {}
const ProbablyRussianWarning = ({}: ProbablyRussianWarningProps) => {
  return (
    <div className='alert alert-info d-flex align-items-center p-5 mb-10'>
      <div className='d-flex flex-column'>
        <span>
          Если вы хотите оплатить подписку с российской карты, то вам нужно переключиться на русский
          язык
        </span>
        <div
          className='menu-item'
          onClick={() => {
            setLanguage(RU_LANGUAGE.lang)
          }}
        >
          <SwitchButton href='#' className={'menu-link d-flex px-5'}>
            <span className='symbol symbol-20px me-4'>
              <img className='rounded-1' src={RU_LANGUAGE.flag} alt='metronic' />
            </span>
            Переключиться
          </SwitchButton>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProbablyRussianWarning)

const SwitchButton = styled.a`
  padding-left: 0 !important;
`
