/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {create} from 'zustand'
import styled from 'styled-components'

import {KTIcon} from '@_metronic/helpers'

import {TreePublishType} from '@api/models'

import GalleryMessenger from '@pages/Gallery/components/GalleryDrawerMessenger/components/GalleryMessenger'

export type MessageAuthorType = {
  id: number
  avatar: {
    extension: string
    id: number
    mime: string
    owner_id: number
    type: number
    url: string
  }
  is_premium: boolean
  first_name: string
  last_name: string | null
  ref_token: string
  role: number
}

export type MessageType = {
  id: number
  message: string
  count_dislikes: number
  count_likes: number
  create_time: string
  update_time: string
  user_id: number
  comment_id?: number
}

export type MessagesInfo = {
  authors: {[id: MessageAuthorType['id']]: MessageAuthorType}
  comments: MessageType[]
}

type PersonsModalStore = {
  opened: boolean
  galleryTreePublish: TreePublishType | null
  sentCallback?: (count: number) => void
  show: (galleryTree: TreePublishType, sentCallback: (count: number) => void) => void
  hide: () => void
}
export const useGalleryMessengerModalStore = create<PersonsModalStore>((set) => ({
  opened: false,
  galleryTreePublish: null,
  show: (galleryTreePublish, sentCallback) =>
    set((state) => ({...state, opened: true, galleryTreePublish, sentCallback})),
  hide: () =>
    set((state) => ({...state, opened: false, galleryTreePublish: null, sentCallback: undefined})),
}))

type GalleryDrawerMessengerProps = {
  refToken?: string
}

const GalleryDrawerMessenger = ({refToken}: GalleryDrawerMessengerProps) => {
  const {opened, hide, galleryTreePublish} = useGalleryMessengerModalStore()

  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>{galleryTreePublish?.name}</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={hide}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>
          {/* end::Header */}

          {/* begin::Body */}
          <GalleryMessenger visible={opened} refToken={refToken} />
          {/* end::Body */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export default GalleryDrawerMessenger

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
