import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'

import {usePersonsStore} from '@store/persons'
import {PersonsModalMode, usePersonsModalStore} from '@components/molecules/PersonsModal'

import Relation from './components/Relation'

import {PersonModel} from '@api/models'
import {RelationType} from '@app/types'

type ParentsProps = {relationType: RelationType; readonly: boolean; person: PersonModel}
const RelationsBlock = ({relationType, readonly, person}: ParentsProps) => {
  const intl = useIntl()

  const {persons} = usePersonsStore()
  const relations = useMemo(() => {
    // console.log('### RelationsBlock.relations', person)
    const personsMap = persons.reduce((res, nextPerson) => {
      res[nextPerson.id] = nextPerson
      return res
    }, {})

    return ((relationType === RelationType.parent ? person?.parents : person?.pets) || [])
      .map((relationId) => personsMap[relationId])
      .filter((relation) => !!relation)
  }, [person, persons, relationType])

  const {show: showPersonSelectModal} = usePersonsModalStore()
  const handleAddRelationClick = () => {
    if (person) {
      showPersonSelectModal(
        {personId: person.id},
        relationType === RelationType.parent
          ? person.type === 1
            ? PersonsModalMode.petParentSelect
            : PersonsModalMode.parentSelect
          : PersonsModalMode.petSelect
      )
    }
  }

  if (!(!readonly || (readonly && relations?.length > 0))) return null

  return (
    <div className='card border-0 shadow-none rounded-0 w-100'>
      <div className='card-header p-0 mb-4'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-900'>
            {intl.formatMessage({
              id: relationType === RelationType.parent ? 'SIM.PARENTS' : 'SIM.PETS',
            })}
          </span>
        </h3>

        {!readonly ? (
          <div className='card-toolbar'>
            <span onClick={handleAddRelationClick} className='btn btn-sm btn-light'>
              {intl.formatMessage({id: 'PERSON.ADD'})}
            </span>
          </div>
        ) : null}
      </div>
      <div className=''>
        {relations?.map((relation) => (
          <div key={relation.id} className='d-flex align-items-center mt-5'>
            <Relation
              relation={relation}
              person={person}
              relationType={relationType}
              readonly={readonly}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(RelationsBlock)
