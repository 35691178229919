import React, {useMemo} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'

import {v4 as uuidv4} from 'uuid'

import {KTIcon} from '@_metronic/helpers'
import moment from 'moment/moment'
import useSessionTs from '@hooks/useSessionTs'

const swapElements = (array, index1, index2) => {
  const arrayClone = [...array]
  arrayClone[index1] = arrayClone.splice(index2, 1, arrayClone[index1])[0]

  return arrayClone
}

type CustomFieldsProps = {
  title?: string
  allowAddAction?: boolean
  allowDeleteAction?: boolean
  values?: [string, string][]
  onChange: (values: ([string, string] | [string, string, string])[]) => void
}
const CustomFields = ({
  title,
  allowAddAction = false,
  allowDeleteAction = true,
  values = [],
  onChange,
}: CustomFieldsProps) => {
  const addField = () => {
    let fields
    if (!values) fields = []
    else fields = [...values]
    fields.push(['', ''])

    onChange([...fields])
  }

  const changeCustomFields = (e) => {
    let key = parseInt(e.currentTarget.dataset.key)
    let fieldId = parseInt(e.currentTarget.dataset.id)
    let fields

    if (!values) fields = []
    else fields = [...values]
    fields[fieldId][key] = e.currentTarget.value

    onChange([...fields])
  }

  const intl = useIntl()

  const removeField = (id) => {
    let fields
    if (!values) fields = []
    else fields = [...values]
    fields.splice(id, 1)

    onChange([...fields])
  }

  const {ts} = useSessionTs()

  const onMove = (index, newIndex) => {
    const newOrder = swapElements(values, index, newIndex).map((value) => [...value, ts])
    // @ts-ignore
    onChange(newOrder)
  }

  return (
    <div className='mt-10 mb-10'>
      <label className='form-label'>{title ?? intl.formatMessage({id: 'ADD.FIELD.TITLE'})}</label>

      {values.map(([name, value], index) => {
        return (
          <div key={index} className='input-group mb-5'>
            <input
              className='form-control'
              type='text'
              value={name}
              placeholder={intl.formatMessage({id: 'ADD.FIELD.NAME'})}
              data-id={index}
              data-key={0}
              onChange={changeCustomFields}
            />
            <input
              type='text'
              className='form-control'
              value={value}
              placeholder={intl.formatMessage({id: 'ADD.FIELD.TEXT'})}
              data-id={index}
              data-key={1}
              onChange={changeCustomFields}
            />
            <Handle
              className='btn btn-secondary btn-icon w-auto px-3'
              disabled={index === 0}
              onClick={() => onMove(index, index - 1)}
            >
              <i className='ki-duotone ki-arrow-up'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </Handle>
            <Handle
              className='btn btn-secondary btn-icon w-auto px-3'
              disabled={index + 1 === values?.length}
              onClick={() => onMove(index, index + 1)}
            >
              <i className='ki-duotone ki-arrow-down'>
                <span className='path1'></span>
                <span className='path2'></span>
              </i>
            </Handle>

            {allowDeleteAction ? (
              <a
                className='btn btn-secondary btn-icon w-auto px-3'
                onClick={() => removeField(index)}
              >
                <KTIcon iconName='trash' className='fs-3' />
              </a>
            ) : null}
          </div>
        )
      })}

      <a
        className='btn btn-secondary d-flex justify-content-center align-items-center'
        onClick={addField}
      >
        <KTIcon iconName='plus' className='fs-3' />
        {intl.formatMessage({id: 'PERSON.ADD'})}
      </a>
    </div>
  )
}

export default React.memo(CustomFields)

const Handle = styled.button`
  height: 40px;
  width: 30px;
`
