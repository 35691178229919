/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'
import '@pqina/pintura/pintura.css'

import {Article, PersonModel} from '@api/models'
import useShareTreeLink from '@hooks/useShareTreeLink'

type Payload = {
  personId: PersonModel['id']
  allowSharing?: boolean
}

type ViewArticleDrawerStore = {
  opened: boolean
  article: Article | null
  payload?: Payload | null
  open: (article: Article, payload?: Payload) => void
  close: () => void
}

export const useViewArticleDrawerStore = create<ViewArticleDrawerStore>((set) => ({
  opened: false,
  article: null,
  payload: null,
  open: (article, payload) => set((state) => ({...state, opened: true, article, payload})),
  close: () => set((state) => ({...state, opened: false, article: null, payload: null})),
}))

type ViewArticleDrawerProps = {
  refToken?: string
}

const ViewArticleDrawer = ({refToken}: ViewArticleDrawerProps) => {
  const intl = useIntl()

  const {opened, article, payload, close} = useViewArticleDrawerStore()

  const [articleContent, setArticleContent] = useState<String>()

  useEffect(() => {
    setArticleContent(article?.html)
  }, [article])

  const shareLinkPerson = useShareTreeLink(
    {personId: payload?.personId, articleId: article?._id, refToken},
    true
  )
  const shareLinkDefault = useShareTreeLink({personId: payload?.personId, refToken}, true)
  useEffect(() => {
    if (opened && payload?.allowSharing) {
      window.history.pushState(null, '', shareLinkPerson)
      return () => {
        window.history.pushState(null, '', shareLinkDefault)
      }
    }
  }, [opened])

  if (!opened) return null

  return (
    <Wrapper className='modal bg-black' tabIndex={-1}>
      <div className='modal-dialog h-100 modal-fullscreen'>
        <div className='modal-content shadow-none'>
          <div className='modal-header'>
            <h3 className='modal-title flex-fill'>
              {intl.formatMessage({id: 'SIM.VIEW.TAB_ARTICLES'})}
            </h3>
            <button type='button' className='btn btn-light me-2' onClick={close}>
              {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
            </button>
          </div>

          <div className='modal-body fs-2'>
            <div className='container' style={{maxWidth: '800px'}}>
              {articleContent && typeof articleContent === 'string' ? (
                <div className={'w-100'} dangerouslySetInnerHTML={{__html: articleContent}} />
              ) : (
                <span>Статья пустая</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export {ViewArticleDrawer}

const Wrapper = styled.div`
  display: flex;

  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
`
