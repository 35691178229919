import React from 'react'
import {setLanguage} from '@_metronic/i18n/Metronici18n'
import {toAbsoluteUrl} from '@_metronic/helpers'
import styled from 'styled-components'

const EN_LANGUAGE = {
  lang: 'en',
  name: 'English',
  flag: toAbsoluteUrl('/media/flags/united-states.svg'),
}

type ProbablyEnglishWarningProps = {}
const ProbablyEnglishWarning = ({}: ProbablyEnglishWarningProps) => {
  return (
    <div className='alert alert-info d-flex align-items-center p-5 mb-10'>
      <div className='d-flex flex-column'>
        {/*<h5 className='mb-1'>Обратите внимание!</h5>*/}
        <span>Если вы хотите оплатить с иностранной карты, переключитесь на Английский язык</span>
        <div
          className='menu-item'
          onClick={() => {
            setLanguage(EN_LANGUAGE.lang)
          }}
        >
          <SwitchButton href='#' className={'menu-link d-flex px-5'}>
            <span className='symbol symbol-20px me-4'>
              <img className='rounded-1' src={EN_LANGUAGE.flag} alt='metronic' />
            </span>
            Переключиться
          </SwitchButton>
        </div>
      </div>
    </div>
  )
}

export default React.memo(ProbablyEnglishWarning)

const SwitchButton = styled.a`
  padding-left: 0 !important;
`
