import React, {CSSProperties, useState} from 'react'
import styled from 'styled-components'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {deleteFile} from '@api/requests'

import {ReactComponent as TrashIcon} from '@assets/icons/svg/trash-icon.svg'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

const MySwal = withReactContent(Swal)

type PhotoProps = {
  style?: CSSProperties
  readonly?: boolean
  photo: {id: number; url: string}
  onDelete: (photoId: number) => void
  onClick: () => void
}
const Photo = ({style, photo, readonly = false, onDelete, ...props}: PhotoProps) => {
  const intl = useIntl()
  const [focus, setFocus] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const handleDeletePress = () => {
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      // text: `Вы хотите удалить персонажа - ${person.first_name} ${person.last_name}`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleting(true)

        deleteFile(photo.id)
          .then(() => {
            MySwal.fire({
              position: 'center',
              icon: 'success',
              title: intl.formatMessage({id: 'PHOTO.DELETE'}),
              showConfirmButton: false,
              timer: 1500,
              heightAuto: false,
            }).then(() => {
              onDelete(photo.id)
            })
          })
          .catch((error) => {
            MySwal.fire({
              title: intl.formatMessage({id: 'PHOTO.DELETE.ERROR'}),
              text: error?.response?.statusText || error?.message,
              icon: 'error',
              heightAuto: false,
            })
          })
          .finally(() => {
            setDeleting(false)
          })
      }
    })

    return
  }

  const photoUrl = useHostBasedUrl(photo.url)

  return (
    <Wrapper style={style} onMouseOver={() => setFocus(true)} onMouseOut={() => setFocus(false)}>
      {focus && !readonly ? (
        <DeleteHover onClick={handleDeletePress}>
          {deleting ? (
            <span className='spinner-border spinner-border-sm align-middle ms-2' />
          ) : (
            <TrashIcon width={20} height={20} />
          )}
        </DeleteHover>
      ) : null}
      <PhotoImage {...props} src={photoUrl} />
    </Wrapper>
  )
}

export default React.memo(Photo)

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  overflow: hidden;

  user-select: none;
`

const DeleteHover = styled.div`
  position: absolute;
  top: -30px;
  right: -30px;
  width: 80px;
  height: 80px;

  padding-right: 20px;
  padding-top: 20px;

  z-index: 1;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(220, 20, 60, 0.5);

  border-radius: 50%;

  opacity: 0.7;

  &:hover {
    opacity: 1;
  }
`

const PhotoImage = styled.img`
  cursor: pointer;

  object-fit: cover;

  width: 100%;
  height: 100%;
`
