/* eslint-disable react/jsx-no-target-blank */
import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import {useIntl} from 'react-intl'
import {useAuth} from '@modules/auth'
import {useLang} from '@_metronic/i18n/Metronici18n'

import {EN_OFFER_LINK, EN_POLICY_LINK, RU_OFFER_LINK, RU_POLICY_LINK} from '@constants/links'

const Footer = () => {
  const {currentUser} = useAuth()
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  const intl = useIntl()

  const language = useLang()

  return (
    <>
      <div className='text-dark order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a
          href='https://thesimstree.com'
          target='_blank'
          className='text-gray-800 text-hover-primary'
        >
          TheSimsTree
        </a>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a
            href={language === 'ru' ? RU_POLICY_LINK : EN_POLICY_LINK}
            target='_blank'
            className='menu-link px-2'
          >
            {intl.formatMessage({id: 'FOOTER.CONFID'})}
          </a>
        </li>

        <li className='menu-item'>
          <a
            href={language === 'ru' ? RU_OFFER_LINK : EN_OFFER_LINK}
            target='_blank'
            className='menu-link px-2'
          >
            {intl.formatMessage({id: 'FOOTER.OFERTA'})}
          </a>
        </li>

        <li className='menu-item'>
          <a
            href={`https://jivo.chat/mm2JHN2QIJ${
              currentUser?.email ? `?email=${currentUser.email}` : ''
            }`}
            target='_blank'
            className='menu-link px-2'
          >
            {intl.formatMessage({id: 'FOOTER.SUPPORT'})}
          </a>
        </li>
      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
