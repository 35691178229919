import React, {useEffect, useState} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'

type CopyLinkProps = {link: string}
const CopyLink = ({link}: CopyLinkProps) => {
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (!copied) {
      return
    }

    setTimeout(() => {
      setCopied(false)
    }, 1500)
  }, [copied])

  return (
    <div className='w-100'>
      <div className='d-flex'>
        <input
          id='kt_share_earn_link_input'
          type='text'
          className='form-control form-control-solid me-3 flex-grow-1'
          name='search'
          value={link}
        />
        <CopyToClipboard text={link} onCopy={() => setCopied(true)}>
          <div className='clearfix bshadow0 pbs highlight-copy btn'>
            <span
              className={`ki-duotone ${copied ? 'ki-copy-success' : 'ki-copy'}`}
              style={{fontSize: 30}}
            >
              <span className='path1' />
              <span className='path2' />
            </span>
          </div>
        </CopyToClipboard>
      </div>
    </div>
  )
}

export default React.memo(CopyLink)
