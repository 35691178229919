/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  const intl = useIntl()

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 h-100'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <div className='w-lg-500px'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5 mt-auto'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            <a
              href='https://thesimstree.com/politika-konfidenczialnosti.html'
              className='px-5'
              target='_blank'
              rel='noreferrer'
            >
              {intl.formatMessage({id: 'FOOTER.CONFID'})}
            </a>

            <a
              href='https://thesimstree.com/publichnaya-oferta-sajta.html'
              className='px-5'
              target='_blank'
              rel='noreferrer'
            >
              {intl.formatMessage({id: 'FOOTER.OFERTA'})}
            </a>

            <a
              href='https://jivo.chat/mm2JHN2QIJ'
              className='px-5'
              target='_blank'
              rel='noreferrer'
            >
              {intl.formatMessage({id: 'FOOTER.SUPPORT'})}
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${require('@assets/backgrounds/blue.jpg')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <Link to='/'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/Logo.svg')} className='h-75px' />
          </Link>
          {/* end::Logo */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
