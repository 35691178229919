import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'

import {usePhotosStore} from '@store/photos'
import {uploadImage} from '@api/requests'
import {UserProfileValues} from '@_metronic/partials/layout/userprofile-drawer/UserProfileDrawer'
import {AVATAR_IMAGE_TARGET_SIZES} from '@constants/imageUploading'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

interface UserProfileDrawerContentProps {
  values?: UserProfileValues
  onValuesChange: (values: UserProfileValues) => void
}

const UserProfileDrawerContent: React.FC<UserProfileDrawerContentProps> = ({
  values,
  onValuesChange,
}) => {
  const intl = useIntl()

  const [avatar, setAvatar] = useState<{id: number; url: string} | null>(null)

  const [first_name, setFirstName] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')

  useEffect(() => {
    onValuesChange({first_name, avatar, password, passwordConfirm})
  }, [first_name, avatar, password, passwordConfirm])

  useEffect(() => {
    if (!values) {
      setFirstName('')
      setAvatar(null)
    } else {
      setFirstName(values.first_name || '')
      setAvatar(values.avatar || null)
    }
  }, [values])

  const butOpenEditorRef = useRef<any>()

  const {selectPhoto} = usePhotosStore()

  const callBackForCrop = (file) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e && e.target && e.target.result) {
        uploadImage(file, 1).then((response) => {
          const {id, url} = response.data
          setAvatar({id, url})
        })
      }
      // @ts-ignore
      setAvatar(e.target.result)
    }
    reader.readAsDataURL(file)
  }
  function handleFiles(event) {
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, 1, AVATAR_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    if (butOpenEditorRef && butOpenEditorRef.current)
      //@ts-ignore
      butOpenEditorRef.current.click()
  }

  const avatarUrl = useHostBasedUrl(avatar?.url)

  return (
    <div className='card-body' id='kt_help_body'>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      {/* begin::Content */}
      <div className='row mb-6'>
        <label className='col-lg-12 col-form-label fw-semibold fs-6'>
          {intl.formatMessage({id: 'USER.PHOTO'})}
        </label>
        <div className='col-lg-12'>
          <div className='image-input image-input-outline' data-kt-image-input='true'>
            <div
              style={{
                backgroundImage: avatarUrl
                  ? `url('${avatarUrl}')`
                  : 'url("https://my-engine.ru/modules/users/avatar.png")',
              }}
              className='image-input-wrapper w-125px h-125px'
            />
            <label
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='change'
              data-bs-toggle='tooltip'
              title='Change avatar'
            >
              <i className='ki-duotone ki-pencil fs-7'>
                <span className='path1' />
                <span className='path2' />
              </i>
              <input onChange={handleFiles} type='file' name='avatar' accept='.png, .jpg, .jpeg' />
              <input type='hidden' name='avatar_remove' />
            </label>
            <span
              className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
              data-kt-image-input-action='cancel'
              data-bs-toggle='tooltip'
              title='Cancel avatar'
            >
              <i className='ki-duotone ki-cross fs-2'>
                <span className='path1' />
                <span className='path2' />
              </i>
            </span>
          </div>
          <div className='form-text'>{intl.formatMessage({id: 'USER.FILE_TYPES'})}</div>
        </div>
      </div>
      <div className='mb-10'>
        <label className='required form-label'>{intl.formatMessage({id: 'USER.FIRST_NAME'})}</label>
        <input
          type='text'
          value={first_name}
          className='form-control'
          onChange={(e) => setFirstName(e.currentTarget.value)}
        />
      </div>
      <h4>{intl.formatMessage({id: 'USER.CHANGE.PASS.TITLE'})}</h4>
      <p className='mb-4'>{intl.formatMessage({id: 'USER.CHANGE.PASS.DESCRIPTION'})}</p>
      <div className='mb-10 pt-4'>
        <label className='form-label'>{intl.formatMessage({id: 'AUTH.INPUT.PASSWORD'})}</label>
        <input
          type='password'
          value={password}
          className='form-control'
          onChange={(e) => setPassword(e.currentTarget.value)}
        />
      </div>
      <div className='mb-10'>
        <label className='form-label'>
          {intl.formatMessage({id: 'AUTH.INPUT.CONFIRM_PASSWORD'})}
        </label>
        <input
          type='password'
          value={passwordConfirm}
          className='form-control'
          onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
        />
      </div>
      {/* end::Content */}
    </div>
  )
}
// "AUTH.INPUT.PASSWORD": "Password",
//   "AUTH.INPUT.CONFIRM_PASSWORD": "Confirm Password",
export {UserProfileDrawerContent}
