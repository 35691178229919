import {usePersonsStore} from '@store/persons'
import {AvatarModel} from '@api/models'

const usePersonAvatars = (personId: number): AvatarModel[] => {
  const {persons} = usePersonsStore()

  const person = persons.find((person) => person.id === personId)

  return person?.params?.avatars || []
}

export default usePersonAvatars
