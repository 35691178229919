import React, {useEffect, useMemo, useRef, useState} from 'react'
import clsx from 'clsx'

import {useAuth} from '@modules/auth'
import {PublishStatusEnum, TreePublishType} from '@api/models'

import {
  dislikeGalleryTree,
  likeGalleryTree,
  markTreeAsFavorite,
  unmarkTreeAsFavorite,
} from '@api/requests'
import {useGalleryMessengerModalStore} from '@pages/Gallery/components/GalleryDrawerMessenger/GalleryDrawerMessenger'
import {useLocation} from 'react-router-dom'

const ANON_LIKED_KEY = '@anon_liked'

type CommentsAndLikesProps = {
  treePublish: TreePublishType
  hideFavorite?: boolean
}
const CommentsAndLikes = ({treePublish, hideFavorite}: CommentsAndLikesProps) => {
  // console.log('### CommentsAndLikes.treePublish', treePublish)
  const {currentUser} = useAuth()
  const {show} = useGalleryMessengerModalStore()
  const [commentsCount, setCommentsCount] = useState(treePublish.count_comments)
  useEffect(() => {
    setCommentsCount(treePublish.count_comments)
  }, [treePublish.count_comments])
  const handleMessengerOpen = () => {
    console.log('### handleMessengerOpen', treePublish)
    show(treePublish, (count) => setCommentsCount(count))
  }

  const anonStorageKey = `${ANON_LIKED_KEY}_${treePublish.token}`

  const [liked, setLiked] = useState(treePublish?.my_reactions?.like === 1)
  const [likedByAnonymous, setLikedByAnonymous] = useState(false)
  const likesCount = useMemo(() => {
    if (currentUser) {
      return (
        treePublish.count_likes +
        (liked && !treePublish?.my_reactions?.like ? 1 : 0) -
        (!liked && treePublish?.my_reactions?.like ? 1 : 0)
      )
    } else {
      return treePublish.count_likes + (likedByAnonymous ? 1 : 0)
    }
  }, [treePublish.count_likes, liked, likedByAnonymous, currentUser])

  useEffect(() => {
    if (!currentUser) {
      const alreadyLiked = localStorage.getItem(anonStorageKey) === 'true'

      if (liked !== alreadyLiked && alreadyLiked) {
        setLiked(true)
      }
    }
  }, [currentUser])

  const handleToggleLike = () => {
    if (currentUser) {
      if (liked) {
        dislikeGalleryTree(treePublish.token).then(() => setLiked(false))
      } else {
        likeGalleryTree(treePublish.token).then(() => setLiked(true))
      }
    } else {
      // check in local storage
      const alreadyLiked = localStorage.getItem(anonStorageKey) === 'true'
      // like it if don't liked already
      if (!alreadyLiked) {
        likeGalleryTree(treePublish.token).then(() => {
          setLikedByAnonymous(true)
          localStorage.setItem(anonStorageKey, 'true')
        })
      }
    }
  }

  const [favorited, setFavorited] = useState<boolean>(treePublish.my_reactions?.favorite === 1)
  useEffect(() => {
    setFavorited(treePublish.my_reactions?.favorite === 1)
  }, [treePublish.my_reactions?.favorite])
  const handleToggleFavorite = () => {
    if (currentUser) {
      if (treePublish.my_reactions?.favorite === 1) {
        unmarkTreeAsFavorite(treePublish.tree_id).then(() => setFavorited(false))
      } else {
        markTreeAsFavorite(treePublish.tree_id).then(() => setFavorited(true))
      }
    } else {
    }
  }

  const buttonRef = useRef<HTMLSpanElement>(null)
  const location = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const commentsFromQuery = searchParams.get('comments')

    if (commentsFromQuery === '1') {
      setTimeout(() => {
        buttonRef.current?.click()
      }, 1000)
    }
  }, [location])

  if (treePublish.status === PublishStatusEnum.private) return null

  return (
    <ul className='nav me-4'>
      <li className='nav-item p-0'>
        <span
          ref={buttonRef}
          className='nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary btn-active-light-primary fw-bold p-2'
          onClick={handleMessengerOpen}
        >
          <i className='ki-duotone ki-message-text-2 fs-2 me-1'>
            <span className='path1' />
          </i>
          {commentsCount}
        </span>
      </li>
      <li className='nav-item p-0'>
        <span
          className='nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary btn-active-light-primary fw-bold p-2'
          onClick={handleToggleLike}
        >
          <i
            className={clsx(
              'ki-duotone ki-heart fs-2 me-1',
              (liked || likedByAnonymous) && 'text-danger'
            )}
          >
            <svg
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M18.3721 4.65439C17.6415 4.23815 16.8052 4 15.9142 4C14.3444 4 12.9339 4.73924 12.003 5.89633C11.0657 4.73913 9.66 4 8.08626 4C7.19611 4 6.35789 4.23746 5.62804 4.65439C4.06148 5.54462 3 7.26056 3 9.24232C3 9.81001 3.08941 10.3491 3.25153 10.8593C4.12155 14.9013 9.69287 20 12.0034 20C14.2502 20 19.875 14.9013 20.7488 10.8593C20.9109 10.3491 21 9.81001 21 9.24232C21.0007 7.26056 19.9383 5.54462 18.3721 4.65439Z'
                fill='currentColor'
              />
            </svg>
          </i>
          {likesCount}
        </span>
      </li>
      {!hideFavorite ? (
        <li>
          <span
            className='nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary btn-active-light-primary fw-bold p-2'
            onClick={handleToggleFavorite}
          >
            <span
              className={clsx('ki-duotone ki-bookmark-2 fs-2 me-1', favorited && 'text-danger')}
            >
              <span className='path1'></span>
              <span className='path2'></span>
            </span>
          </span>
        </li>
      ) : null}
    </ul>
  )
}

export default React.memo(CommentsAndLikes)
