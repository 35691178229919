import * as Yup from 'yup'
import enMessages from '@_metronic/i18n/messages/en.json'
import ruMessages from '@_metronic/i18n/messages/ru.json'
import ukMessages from '@_metronic/i18n/messages/uk.json'
import frMessages from '@_metronic/i18n/messages/fr.json'

import emails from '@assets/emails.json'

const allowedEmailOnly = process.env.REACT_APP_ALLOW_EMAILS_FROM_LIST_ONLY === 'true'

const allMessages = {
  en: enMessages,
  ru: ruMessages,
  uk: ukMessages,
  fr: frMessages,
}

const setLocale = (locale: string) => {
  Yup.setLocale({
    string: {
      email: allMessages[locale]['AUTH.VALIDATION.EMAIL_FORMAT'],
      min: allMessages[locale]['AUTH.VALIDATION.MIN_LENGTH_FIELD'],
      max: allMessages[locale]['AUTH.VALIDATION.MAX_LENGTH_FIELD'],
    },
  })
}

export const registrationSchema = (locale: string) => {
  setLocale(locale)

  return Yup.object().shape({
    allowedEmailOnly: Yup.boolean(),
    firstname: Yup.string()
      .trim()
      .min(3)
      .max(50)
      .required(allMessages[locale]['AUTH.VALIDATION.FIRST_NAME']),
    email: !allowedEmailOnly
      ? Yup.string()
          .email()
          .min(3)
          .max(50)
          .required(allMessages[locale]['AUTH.VALIDATION.EMAIL_REQUIRED'])
          .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            allMessages[locale]['AUTH.VALIDATION.EMAIL_FORMAT']
          )
      : Yup.string()
          .email()
          .min(3)
          .max(50)
          .required(allMessages[locale]['AUTH.VALIDATION.EMAIL_REQUIRED'])
          .oneOf(emails, allMessages[locale]['AUTH.VALIDATION.EMAIL_IS_NOT_IN_ALLOWED_LIST']),
    // lastname: Yup.string()
    //   .min(3)
    //   .max(50)
    //   .required(allMessages[locale]['AUTH.VALIDATION.LAST_NAME']),
    password: Yup.string()
      .min(3)
      .max(50)
      .required(allMessages[locale]['AUTH.VALIDATION.PASSWORD_REQUIRED']),
    changepassword: Yup.string()
      .min(3)
      .max(50)
      .required(allMessages[locale]['AUTH.VALIDATION.PASSWORD_CONFIRMATION'])
      .oneOf(
        [Yup.ref('password')],
        allMessages[locale]['AUTH.VALIDATION.PASSWORD_CONFIRMATION_MATCH']
      ),
    acceptTerms: Yup.bool().required(allMessages[locale]['AUTH.VALIDATION.TERMS']),
  })
}
