/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef} from 'react'
import {usePhotosStore} from '@store/photos'
import {PinturaEditorModal} from '@pqina/react-pintura'
import {
  createMarkupEditorToolStyle,
  createMarkupEditorToolStyles,
  getEditorDefaults,
  markup_editor_locale_en_gb,
  plugin_annotate_locale_en_gb,
} from '@pqina/pintura'
import '@pqina/pintura/pintura.css'
import {
  createDefaultImageReader,
  createDefaultImageWriter,
  setPlugins,
  plugin_finetune,
  plugin_filter,
  plugin_crop,
  plugin_finetune_locale_en_gb,
  plugin_filter_locale_en_gb,
  locale_en_gb,
  plugin_crop_locale_en_gb,
  createDefaultShapePreprocessor,
  markup_editor_defaults,
  plugin_filter_defaults,
  plugin_finetune_defaults,
} from '@pqina/pintura'

import {qualityUploadedFiles} from '@constants/uploadFiles'

const EditPhotosDrawer = ({}) => {
  const {selectedPhoto, targetSize, selectPhoto, aspectShape, callback, editMode} = usePhotosStore()
  const butRef = useRef<any>()

  // TODO for rounded image selector
  /*const editorDefaultsRounded = getEditorDefaults({
    imageReader: createDefaultImageReader(),
    // This will write the output image
    imageWriter: {
      // Scale down the output image
      targetSize: {
        width: 400,
        height: 400,
      },
      // Convert to PNG so masked area is transparent
      mimeType: 'image/jpeg',
      
      // Run custom processing on the image data
      postprocessImageData: (imageData) =>
        new Promise((resolve) => {
          // Create a canvas element to handle the imageData
          const canvas = document.createElement('canvas')
          canvas.width = imageData.width
          canvas.height = imageData.height
          const ctx = canvas.getContext('2d')
          if (ctx) {
            ctx.putImageData(imageData, 0, 0)
            
            // Only draw image where we render our circular mask
            ctx.globalCompositeOperation = 'destination-in'
            
            // Draw our circular mask
            ctx.fillStyle = 'black'
            ctx.beginPath()
            ctx.arc(
              imageData.width * 0.5,
              imageData.height * 0.5,
              imageData.width * 0.5,
              0,
              2 * Math.PI
            )
            ctx.fill()
            
            // Returns the modified imageData
            resolve(ctx.getImageData(0, 0, canvas.width, canvas.height))
          }
        }),
    },
    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,
    ...plugin_filter_defaults,
    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),
    // This will set a square crop aspect ratio
    imageCropAspectRatio: 1,
    // Let's draw a circle on top of the editor preview when in the crop util
    willRenderCanvas: (shapes, state) => {
      const {utilVisibility, selectionRect, lineColor, backgroundColor} = state
      
      // Exit if crop utils is not visible
      if (utilVisibility.crop <= 0) return shapes
      
      // Get variable shortcuts to the crop selection rect
      const {x, y, width, height} = selectionRect
      
      return {
        // Copy all props from current shapes
        ...shapes,
        
        // Now we add an inverted ellipse shape to the interface shapes array
        interfaceShapes: [
          {
            x: x + width * 0.5,
            y: y + height * 0.5,
            rx: width * 0.5,
            ry: height * 0.5,
            opacity: utilVisibility.crop,
            inverted: true,
            backgroundColor: [...backgroundColor, 0.5],
            strokeWidth: 1,
            strokeColor: [...lineColor],
          },
          // Spread all existing interface shapes onto the array
          ...shapes.interfaceShapes,
        ],
      }
    },
    stickers: ['😎', '😘', '😡', '😍'],
  })*/

  const editorDefaults = getEditorDefaults({
    imageReader: createDefaultImageReader(),
    // This will write the output image
    imageWriter: createDefaultImageWriter({
      quality: !editMode ? qualityUploadedFiles : undefined,
      mimeType: 'image/jpeg',
      targetSize: targetSize,
    }),
    // The markup editor default options, tools, shape style controls
    ...markup_editor_defaults,
    ...plugin_filter_defaults,
    ...plugin_finetune_defaults,
    // This handles complex shapes like arrows / frames
    shapePreprocessor: createDefaultShapePreprocessor(),
    // This will set a square crop aspect ratio
    imageCropAspectRatio: aspectShape,

    stickers: ['😎', '😘', '😡', '😍'],

    locale: {
      ...locale_en_gb,
      ...plugin_crop_locale_en_gb,
      ...plugin_finetune_locale_en_gb,
      ...plugin_annotate_locale_en_gb,
      ...markup_editor_locale_en_gb,
    },
  })

  const close = () => {
    butRef.current?.click()
    selectPhoto(null, null, 1)
  }

  const process = (res) => {
    callback?.(res.dest)
    close()
  }
  return (
    <div
      data-bs-keyboard='false'
      className='modal fade bg-black'
      tabIndex={-1}
      id='kt_modal_edit_photo'
    >
      <div className='modal-dialog modal-fullscreen'>
        <div className='modal-content shadow-none'>
          <button style={{opacity: 0, position: 'absolute'}} ref={butRef} data-bs-dismiss='modal' />
          {selectedPhoto ? (
            <div style={{height: '100%'}}>
              <PinturaEditorModal
                {...editorDefaults}
                src={URL.createObjectURL(selectedPhoto)}
                markupEditorToolStyles={createMarkupEditorToolStyles({
                  text: createMarkupEditorToolStyle('text', {
                    // Set default text shape background to transparent
                    backgroundColor: [0, 0, 0, 0],

                    // Set default text shape outline to 0 width and white
                    textOutline: ['0%', [1, 1, 1]],

                    // Set default text shadow, shadow will not be drawn if x, y, and blur are 0.
                    textShadow: ['0%', '0%', '0%', [0, 0, 0, 0.5]],

                    // Allow newlines in inline text
                    disableNewline: false,

                    // Align to left by default, this triggers always showing the text align control
                    textAlign: 'left',

                    // Enable text formatting
                    format: 'html',
                  }),
                })}
                onProcess={process}
                onClose={() => close()}
              />
            </div>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  )
}

export {EditPhotosDrawer}
