/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {EditTreeDrawerContent} from './EditTreeDrawerContent'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {useTreesStore} from '@store/trees'

import {KTIcon} from '../../../helpers'

import {TreeModel} from '@api/models'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {useIntl} from 'react-intl'
import useOnScreen from '@hooks/useOnScreen'

const MySwal = withReactContent(Swal)

const EditTreeDrawer = () => {
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const {updateTree, activeTreeId, trees} = useTreesStore()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const [tree, setTree] = useState<TreeModel | null>(null)

  const [avatar, setAvatar] = useState<object | null>(null)
  useEffect(() => {
    if (activeTreeId !== null && activeTreeId !== undefined && activeTreeId >= 0 && trees) {
      // @ts-ignore
      setTree(trees[activeTreeId])
      setNewTreeValues({
        name: trees[activeTreeId].name,
        description: trees[activeTreeId].description,
        background_id: trees[activeTreeId].background_id,
        avatar_id: null,
      })
    }
  }, [activeTreeId, trees])
  const [newTreeValues, setNewTreeValues] = useState<{
    name: string
    description: string
    avatar_id: number | null
    background_id: number
  }>()
  const handleValuesChange = (data) => {
    setNewTreeValues(data)
  }

  const handleClose = () => {
    closeButtonRef.current?.click()
  }

  const intl = useIntl()

  const handleSaveClick = () => {
    if (!newTreeValues) return

    //@ts-ignore
    updateTree(activeTreeId, {...newTreeValues, avatar})
      .then(() => {
        closeButtonRef.current?.click()
        MySwal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({id: 'MSG.TREE.EDITED'}),
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
      })
      .catch((error) => {
        console.log('### error', error)
        if (error.response.status === 402) {
          showSubscriptionModal()
        }
      })
  }

  const ref = useRef(null)
  const visible = useOnScreen(ref)

  return (
    <div
      id='kt_edittree'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='edittree'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_edittree_toggle'
      data-kt-drawer-close='#kt_edittree_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header' ref={ref}>
          <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'TREE.EDIT.TITLE'})}</h5>

          <div className='card-toolbar'>
            <button
              id='kt_edittree_close'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              type='button'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        {visible ? (
          <EditTreeDrawerContent
            setParentAvatar={setAvatar}
            tree={tree}
            values={newTreeValues}
            onValuesChange={handleValuesChange}
            onClose={handleClose}
          />
        ) : null}
        {/* end::Body */}
        {/* begin::Footer */}
        <div className='card-footer py-5 text-center' id='kt_activities_footer'>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-secondary d-flex justify-content-end me-3'
              id='kt_edittree_close'
              ref={closeButtonRef}
            >
              {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
            </button>
            <button
              className='btn btn-success d-flex justify-content-end'
              disabled={!newTreeValues?.name}
              onClick={handleSaveClick}
            >
              {intl.formatMessage({id: 'TREE.ADD.SAVE'})}
            </button>
          </div>
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {EditTreeDrawer}
