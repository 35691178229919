/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef} from 'react'
import ImageViewer from 'react-simple-image-viewer'

import {usePhotosStore} from '@store/photos'
import {addHostPrefixToUrl} from '@hooks/useHostBasedUrl'

const ViewPhotosDrawer = ({}) => {
  const {selectedPhotos, setPhotos, activePhoto} = usePhotosStore()
  console.log('### activePhoto', activePhoto)
  const butRef = useRef<any>()

  console.log('### selectedPhotos', selectedPhotos)
  const urls = (selectedPhotos || [])
    .map((x) => addHostPrefixToUrl(x.url))
    .filter((url) => typeof url !== 'undefined') as string[]
  console.log('### urls', urls)

  return (
    <div className='modal fade' tabIndex={-1} id='kt_modal_show_photos'>
      <div className='modal-dialog modal-fullscreen'>
        <div className='modal-content shadow-none'>
          <button style={{opacity: 0}} ref={butRef} data-bs-dismiss='modal' />
          {urls?.length && (
            <ImageViewer
              src={urls}
              currentIndex={activePhoto !== null && activePhoto !== undefined ? activePhoto : 0}
              disableScroll={false}
              closeOnClickOutside
              onClose={() => {
                butRef.current?.click()
                setPhotos(null)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export {ViewPhotosDrawer}
