import React, {FC, useState} from 'react'
import {useIntl} from 'react-intl'

import {useAuth} from '@modules/auth'

import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {useCurrentSubscriptionModalStore} from '@components/molecules/CurrentSubscriptionModal/CurrentSubscriptionModal'
import {useTreesStore} from '@store/trees'

import ImportTreeModal from '@components/molecules/ImportTreeModal'

const ToggleUserProfileDrawer: FC = () => {
  const intl = useIntl()

  const [importTreeModalOpen, setImportTreeModalOpen] = useState(false)
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const {currentUser} = useAuth()

  const {trees} = useTreesStore()
  const handleOpenTreeModalClick = () => {
    if (!currentUser?.is_premium && Object.keys(trees).length >= 2) {
      showSubscriptionModal()
    } else {
      setImportTreeModalOpen(true)
    }
  }

  const {show: showCurrentSubscriptionModal} = useCurrentSubscriptionModalStore()
  const handleSubscriptionClick = () => {
    if (currentUser?.is_premium) {
      showCurrentSubscriptionModal()
    } else {
      showSubscriptionModal()
    }
  }

  const handleCloseTreeModalClick = () => {
    setImportTreeModalOpen(false)
  }

  return (
    <>
      <div
        id='kt_userprofile_toggle'
        className='engage-help-toggle menu-item px-5'
        title='Learn & Get Inspired'
        data-bs-toggle='tooltip'
        data-bs-placement='left'
        data-bs-dismiss='click'
        data-bs-trigger='hover'
      >
        <div className='menu-link px-5'>{intl.formatMessage({id: 'MENU.PROFILE'})}</div>
      </div>

      <div className='menu-item px-5'>
        <div className='menu-link px-5' onClick={handleSubscriptionClick}>
          {intl.formatMessage({id: 'MENU.SUBSCRIPTION'})}
        </div>
        <ImportTreeModal show={importTreeModalOpen} onClose={handleCloseTreeModalClick} />
      </div>

      <div className='engage-help-toggle menu-item px-5'>
        <div className='menu-link px-5' onClick={handleOpenTreeModalClick}>
          {intl.formatMessage({id: 'MENU.IMPORT_DYNASTY'})}
        </div>
        <ImportTreeModal show={importTreeModalOpen} onClose={handleCloseTreeModalClick} />
      </div>
    </>
  )
}

export {ToggleUserProfileDrawer}
