import React, {useMemo} from 'react'
import styled from 'styled-components'
import {Gender} from 'dehimer-relatives-tree/lib/types'

import {useSimDrawerStore} from '@_metronic/partials/layout/sim-drawer/SimDrawer'

import useTreeStyles from '@hooks/useTreeStyles'
import useHighlightParents from '@components/organisms/Tree/hooks/useHighlightParents'
import useBlockContextMenu from '@components/organisms/Tree/hooks/useBlockContextMenu'
import useHover from '@components/organisms/Tree/hooks/useHover'
import useFirstLetters from '@components/organisms/Tree/hooks/useFirstLetters'
import useSessionTs from '@hooks/useSessionTs'

import EditButtons from './components/EditButtons'
import SubTreeButton from '@components/atoms/SubTreeButton'

import {NODE_HEIGHT, NODE_WIDTH, RelationsEnum} from '@components/organisms/Tree/constants'

import {PersonModel, TreeModel} from '@api/models'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

type Partner = {
  id: number
  status: RelationsEnum
  avatar: string
  gender: Gender
  name?: string
  parents?: number[]
  person_id?: number
}

type PartnerProps = {
  ownerId: number
  partner: Partner
  treeId?: TreeModel['id']
  size: number
  editMode: boolean
  withPadding: boolean
  subTreeOwner?: PersonModel
  readonly?: boolean
}
const Partner = ({
  ownerId,
  partner,
  size,
  editMode,
  withPadding,
  treeId,
  subTreeOwner,
  readonly,
}: PartnerProps) => {
  // console.log('### Partner.partner', partner)

  const {hover, onMouseOver, onMouseLeave} = useHover()

  const {show: showSimDrawer} = useSimDrawerStore()
  const handleNodePress = (partner: Partner) => (e) => {
    if (partner.id && !editMode) {
      showSimDrawer(partner.id, {allowSharing: !subTreeOwner})
    } else {
      e.preventDefault(true)
      e.stopPropagation()
    }
  }

  const clickable = !editMode && typeof partner.id !== 'undefined'

  const firstLetters = useFirstLetters(partner.name)

  const highlight = useHighlightParents(partner, false, editMode)
  const ref = useBlockContextMenu()

  const {
    avatarBorderColor,
    avatarBorderWidth = 2,
    avatarBorderRadius,
    avatarBorderType,
  } = useTreeStyles(treeId)
  const avatarBorderWidthParsed = parseInt(`${avatarBorderWidth}`)
  const avatarBorderRadiusParsed = (size / NODE_HEIGHT) * parseInt(`${avatarBorderRadius}`)

  const padding = withPadding ? 5 : 0

  const {ts} = useSessionTs()
  const avatarUrl = useHostBasedUrl(partner.avatar)
  const avatar = useMemo(() => {
    if (avatarUrl) {
      return <AvatarBackground src={`${avatarUrl}?ts=${ts}`} />
    }

    if (firstLetters) {
      return <Initials $size={size}>{firstLetters}</Initials>
    }

    return <AvatarBackground src={'https://my-engine.ru/modules/users/avatar.png'} />
  }, [avatarUrl, firstLetters, ts, size])

  return (
    <Wrapper
      id='kt_sim_toggle'
      ref={ref}
      $size={size}
      $clickable={clickable}
      $highlight={highlight}
      $padding={padding}
      onClick={handleNodePress(partner)}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <RelationWrapper>
        {/*<StatusIcons style={{width: '100%', height: '100%', backgroundColor: 'red'}} />*/}
        <RelationIcon
          src={require(`../../../../../assets/relations/${RelationsEnum[partner.status]}.png`)}
        />
      </RelationWrapper>
      <RightPartWrapper>
        <Avatar
          $size={size}
          $avatarBorderColor={avatarBorderColor}
          $avatarBorderWidth={avatarBorderWidthParsed}
          $avatarBorderRadius={avatarBorderRadiusParsed}
          $avatarBorderType={avatarBorderType as string}
          $padding={padding}
        >
          {avatar}
        </Avatar>

        {editMode ? (
          <EditWrapper $size={size} $avatarBorderWidth={avatarBorderWidthParsed}>
            <EditButtons
              ownerId={ownerId}
              person_id={partner.id}
              hover={hover}
              size={size - avatarBorderWidthParsed * 2}
              relationType={partner.status}
            />
          </EditWrapper>
        ) : null}

        <SubTreeButton
          personId={partner.id}
          treeId={treeId}
          hover={hover}
          nodeSize={size}
          avatarBorderWidthParsed={avatarBorderWidthParsed}
          editMode={editMode}
        />
      </RightPartWrapper>
    </Wrapper>
  )
}

export default React.memo(Partner)

const Wrapper = styled.div<{
  $size: number
  $clickable: boolean
  $highlight: boolean
  $padding: number
  $debugColor?: string
}>`
  display: flex;

  justify-content: flex-start;
  align-items: center;

  height: ${({$size}) => $size}px;

  margin-left: -${NODE_WIDTH / 2 + 14}px;
  padding: ${({$padding}) => $padding}px;
  padding-right: 0;

  cursor: ${({$clickable}) => ($clickable ? 'pointer' : 'initial')};

  position: relative;

  opacity: ${({$highlight}) => ($highlight ? 1 : 0.4)};
  transition: opacity 300ms;

  background-color: #${({$debugColor}) => $debugColor};
`

const RightPartWrapper = styled.div<{$debugColor?: string}>`
  position: relative;
  width: 100%;
  height: 100%;

  background-color: #${({$debugColor}) => $debugColor};
`

const Avatar = styled.div<{
  $size: number
  $avatarBorderColor?: string | number
  $avatarBorderWidth: number
  $avatarBorderRadius: number
  $avatarBorderType: string
  $padding: number
}>`
  width: ${({$size, $padding}) => $size - $padding * 2}px;
  height: ${({$size, $padding}) => $size - $padding * 2}px;

  border: ${({$avatarBorderType}) => $avatarBorderType}
    ${({$avatarBorderWidth}) => $avatarBorderWidth}px
    ${({$avatarBorderColor}) => $avatarBorderColor};
  border-radius: ${({$avatarBorderRadius}) => $avatarBorderRadius / 2}px;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.1);

  box-sizing: border-box;

  overflow: hidden;
`

const AvatarBackground = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  flex: 1;
`

const Initials = styled.div<{$size: number}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  width: 100%;
  height: 100%;

  font-size: ${({$size}) => $size * 0.3}px;
  color: #fff;
  font-weight: 500;
`

const EditWrapper = styled.div<{$size: number; $avatarBorderWidth: number}>`
  position: absolute;
  left: 0;
  top: 0;

  height: ${({$size, $avatarBorderWidth}) => $size - $avatarBorderWidth * 2}px;
  width: ${({$size, $avatarBorderWidth}) => $size - $avatarBorderWidth * 2}px;

  border-radius: 50%;
  box-sizing: border-box;
`

const RelationWrapper = styled.div`
  min-width: 20px;
  width: 20px;
  height: 20px;
  margin-right: 13px;
`

const RelationIcon = styled.img`
  width: 100%;
`
