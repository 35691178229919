/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import styled from 'styled-components'
import {create} from 'zustand'
import '@pqina/pintura/pintura.css'
import EditorJS from '@editorjs/editorjs'
import ImageTool from '@editorjs/image'
import List from '@editorjs/list'
import Embed from '@editorjs/embed'
import Header from '@editorjs/header'
import Quote from '@editorjs/quote'

import {ARTICLES_URL, createPersonArticle, editPersonArticle} from '@api/requests'
import {useArticleStore} from '@store/articles'
import {Article, PersonModel} from '@api/models'

type OpenParams = {article?: Article; personId: PersonModel['id']}

type EditArticleDrawerStore = {
  opened: boolean
  article: Article | null
  personId: PersonModel['id'] | null
  open: ({article, personId}: OpenParams) => void
  close: () => void
}

export const useEditArticleDrawerStore = create<EditArticleDrawerStore>((set) => ({
  opened: false,
  article: null,
  personId: null,
  open: (params) => set((state) => ({...state, opened: true, ...params})),
  close: () => set((state) => ({...state, opened: false, article: null, personId: null})),
}))

const EditArticleDrawer = ({}) => {
  const intl = useIntl()

  const {editArticle, addArticle} = useArticleStore()
  const {opened, close, article, personId} = useEditArticleDrawerStore()

  const [articleName, setArticleName] = useState('')
  const [articleSubTitle, setArticleSubTitle] = useState('')

  const [editor, setEditor] = useState<null | EditorJS>(null)
  const [valid, setValid] = useState(true)

  useEffect(() => {
    if (!opened) {
      setArticleName('')
      setArticleSubTitle('')
    }
  }, [opened])

  const createEditor = (content: Article | null) => {
    if (editor) {
      try {
        editor.destroy()
      } catch (e) {}
    }
    if (!content) {
      const editor_ = new EditorJS({
        autofocus: true,
        placeholder: intl.formatMessage({id: 'SIM.VIEW.WRITE_HERE'}),
        tools: {
          header: Header,
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: `${ARTICLES_URL}/uploadFile`, // Your backend file uploader endpoint
                byUrl: `${ARTICLES_URL}/fetchUrl`, // Your endpoint that provides uploading by Url
              },
            },
          },
          embed: Embed,
          quote: Quote,
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
        },
        holder: 'paper',
      })
      setEditor(editor_)
    } else {
      const editor_ = new EditorJS({
        autofocus: true,
        placeholder: intl.formatMessage({id: 'SIM.VIEW.WRITE_HERE'}),
        tools: {
          header: {
            class: Header,
            config: {
              placeholder: intl.formatMessage({id: 'SIM.VIEW.ENTER_A_HEADER'}),
              levels: [1, 2, 3, 4],
              defaultLevel: 2,
            },
          },
          image: {
            class: ImageTool,
            config: {
              endpoints: {
                byFile: `${ARTICLES_URL}/uploadFile`, // Your backend file uploader endpoint
                byUrl: `${ARTICLES_URL}/fetchUrl`, // Your endpoint that provides uploading by Url
              },
            },
          },
          embed: Embed,
          quote: Quote,
          list: {
            class: List,
            inlineToolbar: true,
            config: {
              defaultStyle: 'unordered',
            },
          },
        },
        holder: 'paper',
        data: content.doc,
      })
      setEditor(editor_)
    }
  }
  const save = (e) => {
    console.log('### save.articleName', articleName)
    if (!articleName) {
      setValid(false)
      return
    }
    if (editor) {
      console.log('### editor')
      editor.save().then((outputData) => {
        if (article) {
          console.log('### article')
          editPersonArticle(personId, article._id, articleName, outputData, articleSubTitle).then(
            (r) => {
              let data = r.data
              console.log('### data', data)
              if (data.result) {
                editArticle(article._id, outputData, articleName, articleSubTitle, data.html)
              }
            }
          )
        } else {
          console.log('### !article')
          createPersonArticle(personId, articleName, outputData, articleSubTitle).then((r) => {
            let data = r.data
            if (data.result) {
              addArticle({
                _id: data.doc_id,
                sort:0,
                html: data.html,
                person_id: personId,
                doc: outputData,
                createdAt: new Date(),
                subtitle: articleSubTitle,
                name: articleName,
              })
            }
          })
        }
        close()
        setArticleName('')
        setArticleSubTitle('')
      })
    }
  }

  useEffect(() => {
    if (opened) {
      createEditor(article)

      if (article) {
        setArticleName(article.name)
        setArticleSubTitle(article.subtitle)
      }
    }
  }, [opened, article])

  return (
    <Wrapper className='modal bg-black' data-bs-keyboard='false' tabIndex={-1} $hidden={!opened}>
      <div className='modal-dialog h-100 modal-fullscreen'>
        <div className='modal-content shadow-none'>
          <div className='modal-header'>
            <h3 className='modal-title flex-fill'>
              {intl.formatMessage({id: 'SIM.VIEW.TAB_ARTICLES'})}
            </h3>
            <button type='button' className='btn btn-light me-2' onClick={close}>
              {intl.formatMessage({id: 'ACTIONS.CANCEL'})}
            </button>
            <button onClick={save} type='button' className='btn btn-primary'>
              {intl.formatMessage({id: 'ACTIONS.SAVE'})}
            </button>
          </div>

          <div className='modal-body fs-2'>
            <div className='container' style={{maxWidth: '800px'}}>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='mb-3'>
                    <label htmlFor='articleName' className='form-label'>
                      {intl.formatMessage({id: 'SIM.VIEW.TITLE'})}
                    </label>
                    <input
                      type='text'
                      value={articleName}
                      className={`form-control ${valid ? '' : 'is-invalid'}`}
                      placeholder=''
                      autoComplete='one-time-code'
                      onChange={(e) => {
                        setValid(true)
                        setArticleName(e.currentTarget.value)
                      }}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='mb-3'>
                    <label htmlFor='articleSubTitle' className='form-label'>
                      {intl.formatMessage({id: 'SIM.VIEW.SUBTITLE'})}
                    </label>
                    <input
                      type='text'
                      value={articleSubTitle}
                      className={`form-control`}
                      placeholder=''
                      autoComplete='one-time-code'
                      onChange={(e) => {
                        setArticleSubTitle(e.currentTarget.value)
                      }}
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div id='paper'></div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export {EditArticleDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;
`
