import React, {useMemo} from 'react'
import styled from 'styled-components'

import useTreeStyles from '@hooks/useTreeStyles'

const Connector = ({
  connector,
  width,
  height,
  treeId,
  node,
  person,
  inHeirChain,
  heirChainNodes,
  shadow,
}) => {
  const {
    lineColor,
    heirLineColor,
    lineWidth,
    lineShadowWidth,
    lineShadowColor,
    heirLineEnabled: heirLineEnabledString,
    firstNameDisplayingEnabled: firstNameDisplayingEnabledString,
    lastNameDisplayingEnabled: lastNameDisplayingEnabledString,
    diedStatusDisplayingEnabled: diedStatusDisplayingEnabledString,
  } = useTreeStyles(treeId)

  const heirLineEnabled = heirLineEnabledString === 'true'
  const shadowStyleProp = shadow ? `0px 0px ${lineShadowWidth}px ${lineShadowColor}` : 'none'

  const lineWidthParsed = parseInt(`${lineWidth}`)
  const childrenInHeirChain =
    !node?.children?.length || node?.children?.some(({id}) => heirChainNodes?.includes(id))

  const style = useMemo(() => {
    const [x1, y1, x2, y2, {type}] = connector

    const calculatedStyle = {
      backgroundColor: 'transparent',
      width: Math.max(lineWidthParsed, (x2 - x1) * width + lineWidthParsed),
      height: Math.max(lineWidthParsed, (y2 - y1) * height + lineWidthParsed),
      transform: `translate(${x1 * width}px, ${y1 * height}px)`,
    }

    // from parents to children
    if (type === 'children1') {
      const {partners} = person || {}
      const partnersCount = partners?.length > 2 ? partners?.length / 2 : 1

      const firstNameDisplayingEnabled = firstNameDisplayingEnabledString === 'true'
      const lastNameDisplayingEnabled = lastNameDisplayingEnabledString === 'true'
      const diedStatusDisplayingEnabled = diedStatusDisplayingEnabledString === 'true'

      let divider = 2

      if (partners.length === 0) {
        if (firstNameDisplayingEnabled) {
          divider = divider + 2
        }
        if (lastNameDisplayingEnabled) {
          divider = divider + 2
        }
        if (diedStatusDisplayingEnabled) {
          divider = divider + 2
        }
      }

      const newHeight = height / divider / partnersCount + 4

      const offset = height - newHeight

      calculatedStyle.backgroundColor =
        childrenInHeirChain && heirLineEnabled ? heirLineColor : lineColor
      calculatedStyle.height = newHeight
      calculatedStyle.transform = `translate(${x1 * width}px, ${
        y1 * height + offset + lineWidthParsed
      }px)`

      calculatedStyle.boxShadow = shadowStyleProp
    }

    // from children to parents
    if (type === 'children2') {
      calculatedStyle.backgroundColor = inHeirChain && heirLineEnabled ? heirLineColor : lineColor
      calculatedStyle.height = height / 2
      calculatedStyle.boxShadow = shadowStyleProp
    }

    // between node and partnerschildren4
    if (type === 'children3') {
    }

    if (type === 'children4') {
    }

    // between children
    if (type === 'children5') {
      calculatedStyle.backgroundColor = lineColor
      calculatedStyle.transform = `translate(${x1 * width + 2}px, ${y1 * height}px)`
      calculatedStyle.width = Math.max(lineWidthParsed, (x2 - x1) * width)
      calculatedStyle.boxShadow = shadowStyleProp
    }

    // horizontal from parent to children
    if (type === 'children55' && heirLineEnabled && inHeirChain) {
      calculatedStyle.backgroundColor = heirLineColor
    }

    // horizontal from children to parents
    if (type === 'children6') {
      calculatedStyle.backgroundColor =
        childrenInHeirChain && heirLineEnabled ? heirLineColor : lineColor
      calculatedStyle.boxShadow = shadowStyleProp
    }

    return calculatedStyle
  }, [connector, height, width])

  return <Line $lineWidth={lineWidthParsed} style={style} />
}

export default React.memo(Connector)

const Line = styled.i`
  border-radius: ${({$lineWidth}) => $lineWidth}px;
  overflow: hidden;
  position: absolute;
  pointer-events: none;
`
