import {useEffect, useMemo, useState} from 'react'
import {v4 as uuid} from 'uuid'

import {editPersonAx, getPagedSims} from '@api/requests'
import {PersonModel, TreeModel} from '@api/models'
import {usePersonsStore} from '@store/persons'

const useSubTree = ({id: personId}: PersonModel) => {
  const {persons, updatePersons} = usePersonsStore()

  const [initialized, setInitialized] = useState(false)
  const indexOfPerson = persons.findIndex(({id}) => personId === id)
  const person = persons[indexOfPerson]

  useEffect(() => {
    if (personId) {
      setInitialized(false)
    }
  }, [personId])

  const subTree = person.subtree

  useEffect(() => {
    if (!person.subtree) {
      if (indexOfPerson !== -1) {
        const sutTreeOwnerPerson = {
          ...person,
          subtree: {
            nodes: [
              {
                id: uuid(),
                person_id: person.id,
              },
            ],
          },
          is_subtree: 1,
        }

        console.log('### useSubTree.sutTreeOwnerPerson', sutTreeOwnerPerson)

        // @ts-ignore
        editPersonAx(personId, sutTreeOwnerPerson).then((response) => {
          const updatedPerson = response.data
          console.log('### useSubTree.updatedPerson', updatedPerson)

          const personsClone = [...persons]
          const personIndex = personsClone.findIndex(({id}) => id === updatedPerson.id)

          if (personIndex !== -1) {
            personsClone[personIndex] = updatedPerson
            updatePersons(personsClone, true)
          }
        })
      }
    }
  }, [person?.subtree])

  const nodes: TreeModel['nodes'] | null = useMemo(() => subTree?.nodes || [], [subTree?.nodes])

  useEffect(() => {
    if (nodes?.length) {
      const personIds = persons.map(({id}) => id)
      const nodePersonIds: number[] = nodes
        ?.map(({person_id}) => person_id)
        .filter((id) => id) as number[]

      const nodePartnerPersonIds = person.partners.map(({partner_id}) => partner_id)
      console.log('### nodePartnerPersonIds', nodePartnerPersonIds)
      const personToGetIds = [...nodePersonIds, ...nodePartnerPersonIds].filter(
        (id) => id && !personIds.includes(id)
      ) as number[]
      console.log('### personToGetIds', personToGetIds)

      // if (personToGetIds.length > 0) {
      const fields = 'id,first_name,last_name,avatar,is_alive,is_subtree,pets,parents,partners'

      getPagedSims({
        ids: personToGetIds,
        fields,
      }).then((response) => {
        console.log('### response', response)
        const nodePersons = response.data as PersonModel[]

        console.log('### nodePersons', nodePersons)

        const partnersPetAndParentIds: number[] = [...persons, ...nodePersons]
          .map(({pets = [], parents = [], partners = []}) => [
            ...pets,
            ...parents,
            ...partners?.map(({partner_id}) => partner_id),
          ])
          .flat()
          .filter((id) => !personIds.includes(id) && !nodePersonIds.includes(id))

        console.log('### partnersPetAndParentIds', partnersPetAndParentIds)

        return getPagedSims({
          ids: partnersPetAndParentIds,
          fields,
          ignoreHeaders: true,
        }).then((response) => {
          const nodeRelatedPersons = response.data
          const partnersPetAndParentOfReceivedPersonsIds: number[] = nodeRelatedPersons
            .map(({pets, parents}) => [...pets, ...parents])
            .flat()
            .filter(
              (id) =>
                !personIds.includes(id) &&
                !nodePersonIds.includes(id) &&
                !partnersPetAndParentIds.includes(id)
            )

          console.log(
            '### partnersPetAndParentOfReceivedPersonsIds',
            partnersPetAndParentOfReceivedPersonsIds
          )

          return getPagedSims({
            ids: partnersPetAndParentOfReceivedPersonsIds,
            fields,
          }).then((response) => {
            console.log('### response', response)
            const rawPersons = [...persons, ...nodePersons, ...nodeRelatedPersons, ...response.data]
            const alreadyInclidedPersonIdMap: {[key: PersonModel['id']]: boolean} = {}
            const filteredPersons = rawPersons.filter((person) => {
              if (alreadyInclidedPersonIdMap[person.id]) return false

              alreadyInclidedPersonIdMap[person.id] = true

              return true
            })

            updatePersons(filteredPersons, true, () => {
              setInitialized(true)
            })
          })
        })
      })
      // } else {
      //   setTimeout(() => {
      //     setInitialized(true)
      //   }, 200)
      // }
    }
  }, [nodes])

  return {initialized, nodes}
}

export default useSubTree
