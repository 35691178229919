import {create} from 'zustand'

import {AvatarModel} from '@api/models'
import {PinturaTargetSize} from '@pqina/pintura'

type PersonsStore = {
  selectedPhotos?: AvatarModel[] | null
  selectedPhoto?: Blob | MediaSource | null
  activePhoto?: number | null
  aspectShape?: number | 1
  targetSize?: PinturaTargetSize
  editMode?: boolean
  callback?: ((file: File | null) => void) | null
  setPhotos: (photos: AvatarModel[] | null) => void
  setActivePhoto: (id: number | null) => void
  selectPhoto: (
    photo: Blob | MediaSource | null,
    returnImage: ((file) => void) | null,
    aspectShape?: number,
    targetSize?: PinturaTargetSize,
    editMode?: boolean
  ) => void
}

export const usePhotosStore = create<PersonsStore>((set) => ({
  selectedPhotos: null, // TODO initialize in sidebar
  selectedPhoto: null,
  aspectShape: 1,
  callback: null,
  activePhoto: null, // TODO initialize in sidebar
  setActivePhoto: async (id) => {
    set((state) => {
      return {
        ...state,
        activePhoto: id,
      }
    })
  },
  selectPhoto: (photo, returnImage, shape, targetSize, editMode) => {
    set((state) => {
      return {
        ...state,
        callback: returnImage,
        aspectShape: shape,
        selectedPhoto: photo,
        targetSize,
        editMode: true,
      }
    })
  },
  setPhotos: async (photos) => {
    console.log('### setPhotos', photos)
    set((state) => {
      return {
        ...state,
        selectedPhotos: photos,
      }
    })
  },
}))
