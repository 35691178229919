import React, {CSSProperties, useState} from 'react'

import {usePersonsStore} from '@store/persons'
import {usePhotosStore} from '@store/photos'

import usePersonAvatars from '@hooks/usePersonAvatars'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

import Photo from '@components/molecules/CustomGallery/components/Photo'

import {PersonModel} from '@api/models'
import {useAuth} from '@modules/auth'

const defaultAvatarStyle: CSSProperties = {
  width: '100%',
  height: 150,
  borderRadius: 12,
  cursor: 'pointer',
  objectFit: 'cover',
}

type SimDrawerAvatarsProps = {readonly: boolean; personId: PersonModel['id']}
const SimDrawerAvatars = ({readonly, personId}: SimDrawerAvatarsProps) => {
  const avatars = usePersonAvatars(personId)

  const {setPhotos, setActivePhoto} = usePhotosStore()
  const {persons, editPerson} = usePersonsStore()
  const foundPerson = persons.find(({id}) => id === personId)
  const avatarUrl = useHostBasedUrl(foundPerson?.avatar?.url)

  const {currentUser} = useAuth()
  const handlePhotoDelete = (photoId: number) => {
    console.log('### handlePhotoDelete', personId, photoId, foundPerson?.params.avatars)
    if (foundPerson) {
      const newPersonData = {
        avatar: photoId === foundPerson?.avatar?.id ? null : foundPerson?.avatar,
        params: {
          ...foundPerson?.params,
          avatars: foundPerson?.params.avatars.filter(({id}) => id !== photoId),
        },
      }

      console.log('### newPersonData', newPersonData)
      // setDeletedPhotoIds((state) => [...(state || []), photoId])

      editPerson(personId, newPersonData, {currentUser})
    }
  }

  // const filteredAvatars = avatars.filter((photo) => !deletedPhotoIds.includes(photo.id))

  // TODO add delete

  return (
    <>
      {!readonly || (readonly && avatars?.length > 0) ? (
        <div className='row gy-3 mb-5 mt-5'>
          {/*{avatars?.length > 0 ? (
            <CustomGallery currentPhoto={avatarUrl} readonly={readonly} photos={avatars} />
          ) : null}*/}
          {avatars?.map((photo, index) => (
            <div key={index} className='col-md-6'>
              <Photo
                //@ts-ignore
                style={defaultAvatarStyle}
                readonly={readonly || foundPerson?.avatar?.id === photo.id}
                // src={photo.url}
                photo={photo}
                data-bs-toggle='modal'
                data-bs-target='#kt_modal_show_photos'
                onDelete={handlePhotoDelete}
                onClick={() => {
                  // @ts-ignore
                  setPhotos(avatars)
                  setActivePhoto(index)
                }}
              />
            </div>
          ))}
        </div>
      ) : null}
    </>
  )
}

export default React.memo(SimDrawerAvatars)
