import React from 'react'
import styled from 'styled-components'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import {useIntl} from 'react-intl'

import {usePersonsStore} from '@store/persons'
import {PersonsModalMode, usePersonsModalStore} from '@components/molecules/PersonsModal'

import {ReactComponent as TrashIcon} from '@assets/icons/svg/trash-icon.svg'

import {RelationsEnum} from '@components/organisms/Tree/constants'

const MySwal = withReactContent(Swal)

type EditButtonsProps = {
  ownerId: number
  person_id: number
  hover: boolean
  size: number
  relationType: RelationsEnum
}
const EditButtons = ({ownerId, person_id, hover, size, relationType}: EditButtonsProps) => {
  const intl = useIntl()
  const {show: showPersonSelectModal} = usePersonsModalStore()
  const {deletePartnerOfPerson} = usePersonsStore()

  const handleEditPress = (e) => {
    e.preventDefault(true)
    e.stopPropagation()
    showPersonSelectModal(
      {personId: ownerId, partnerId: person_id, relationType},
      PersonsModalMode.partnerRelationUpdate
    )
  }

  const handleDeletePress = (e) => {
    console.log('### handleDeletePress')
    e.preventDefault(true)
    e.stopPropagation()

    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'YOU.WHANT'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'DELETE'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deletePartnerOfPerson(ownerId, person_id, (error) => {
          MySwal.fire({
            title: 'Ошибка удаления партнёра',
            text: error,
            icon: 'error',
            heightAuto: false,
          })
        })
      }
    })
  }

  return (
    <Wrapper $size={size}>
      <EditButton $visible={hover} $size={size} onClick={handleEditPress}>
        <Icon src={require(`../../../../assets/icons/edit.png`)} />
      </EditButton>

      {person_id ? (
        <TrashButton $visible={hover} $size={size} onClick={handleDeletePress}>
          <TrashIcon height={20} />
        </TrashButton>
      ) : null}
    </Wrapper>
  )
}

export default React.memo(EditButtons)

const BUTTON_SIZE = 32
const BUTTON_ICON_SIZE = 16

const Wrapper = styled.div<{$size: number}>`
  width: ${({$size}) => $size}px;
  height: ${({$size}) => $size}px;

  position: relative;
`

const Button = styled.div<{$visible: boolean}>`
  width: ${BUTTON_SIZE}px;
  height: ${BUTTON_SIZE}px;
  border-radius: 50%;
  background-color: #46bc60;

  box-sizing: border-box;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  transition: opacity;
  transition-duration: 100ms;

  opacity: ${({$visible}) => ($visible ? 0.8 : 0)};

  &:hover {
    opacity: 1;
  }
`

const Icon = styled.img`
  width: ${BUTTON_ICON_SIZE}px;
  height: ${BUTTON_ICON_SIZE}px;
`

const EditButton = styled(Button)<{$size: number}>`
  position: absolute;
  left: -${BUTTON_SIZE / 2}px;
  top: ${({$size}) => $size / 2 - BUTTON_SIZE / 2}px;
`

const TrashButton = styled(Button)<{$size: number}>`
  position: absolute;

  right: -${BUTTON_SIZE / 2}px;
  top: ${({$size}) => $size / 2 - BUTTON_SIZE / 2}px;

  background-color: crimson;
`
