import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import clsx from 'clsx'
import {useIntl} from 'react-intl'

import useCalculateTreeLevels from '@components/organisms/Tree/hooks/useCalculateTreeLevels'

import {useLayout} from '../../core'
import {TreeModel} from '@api/models'

import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import SVG from 'react-inlinesvg'
import {useViewTreeDrawerStore} from '@_metronic/partials/layout/viewtree-drawer/ViewTreeDrawer'
import useHostBasedUrl from '@hooks/useHostBasedUrl'

type ToolbarWrapperProps = {
  className?: string
  tree: TreeModel
  editMode?: boolean
  readonly?: boolean
  refToken?: string
  children?: React.ReactNode
  onEditModeChange?: (value: boolean) => void
}
const ToolbarWrapper = ({
  className,
  tree,
  editMode,
  readonly,
  children,
  onEditModeChange,
}: ToolbarWrapperProps) => {
  const intl = useIntl()

  const treeLevelsCount = useCalculateTreeLevels(tree)
  const [treeAvatar, setTreeAvatar] = useState<{url: string} | null>(null)
  useEffect(() => {
    if (tree) {
      // @ts-ignore
      setTreeAvatar(tree.avatar)
    }
  }, [tree])
  const {config, classes} = useLayout()

  const {show} = useViewTreeDrawerStore()
  const handleOpenTreeCard = () => {
    show(tree.id)
  }
  const treeAvatarUrl = useHostBasedUrl(treeAvatar?.url)

  if (!config.app?.toolbar?.display) {
    return null
  }

  return (
    <div
      className={clsx(
        'app-toolbar',
        classes.toolbar.join(' '),
        config?.app?.toolbar?.class,
        className
      )}
    >
      <Container
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        <div className='cursor-pointer symbol symbol-35px me-4' onClick={handleOpenTreeCard}>
          <img
            src={treeAvatarUrl ?? 'https://my-engine.ru/modules/users/avatar.png'}
            className='rounded-3'
            alt='user'
          />
        </div>

        <div
          data-kt-swapper='true'
          data-kt-swapper-mode='prepend'
          data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
          className='page-title d-flex flex-wrap flex-fill me-3 flex-column justify-content-center'
        >
          <h1
            className={clsx(
              'page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center d-sm-flex',
              !readonly && 'd-none'
            )}
          >
            {tree?.name}
          </h1>
          <ul
            className={clsx(
              'breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 d-sm-flex',
              !readonly && 'd-none'
            )}
          >
            <li className='breadcrumb-item text-muted'>
              <span className='text-muted'>{intl.formatMessage({id: 'TREE.GENERATIONS'})}</span>
            </li>
            <li className='breadcrumb-item'>
              <span className='bullet bg-gray-400 w-5px h-2px' />
            </li>
            <li className='breadcrumb-item text-dark'>{treeLevelsCount}</li>
          </ul>
        </div>

        {children}

        {onEditModeChange ? (
          <>
            <div className='form-check form-switch form-check-custom form-check-solid'>
              <label className='form-check-label me-2'>
                <KTIcon iconName='pencil' className='fs-3' />
              </label>
              <input
                className='form-check-input'
                type='checkbox'
                value=''
                checked={editMode}
                onChange={() => onEditModeChange?.(!editMode)}
              />
            </div>
            <button
              id={'kt_tree_customization_toggle'}
              className='btn btn-sm btn-icon  btn-secondary ms-2'
            >
              <i className='ki-duotone ki-setting-4 fs-4 me-1'></i>
            </button>
            <button
              id={'kt_tree_publish_toggle'}
              className='btn btn-sm btn-icon btn-secondary ms-2'
            >
              <ShareIcon src={toAbsoluteUrl('/media/icons/duotune/arrows/arr078.svg')} />
            </button>
          </>
        ) : null}
      </Container>
    </div>
  )
}

export {ToolbarWrapper}

const Container = styled.div`
  padding-left: 0 !important;
  padding-right: 0 !important;
`

const ShareIcon = styled(SVG)`
  height: 20px;
  color: #78829d;
`
