import React, {useMemo} from 'react'
import styled from 'styled-components'

import {PersonModel, TreeModel} from '@api/models'

import {useAuth} from '@modules/auth'
import {getPerson} from '@api/requests'

import {useSubTreeModalStore} from '@components/molecules/SubTreeModal/SubTreeModal'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {usePersonsStore} from '@store/persons'

import {KTIcon} from '@_metronic/helpers'

type SubTreeButtonProps = {
  personId: PersonModel['id'] | null
  treeId?: TreeModel['id']
  hover: boolean
  nodeSize: number
  avatarBorderWidthParsed: number
  editMode: boolean
}
const SubTreeButton = ({
  personId,
  treeId,
  nodeSize,
  hover,
  avatarBorderWidthParsed,
  editMode,
}: SubTreeButtonProps) => {
  const {currentUser} = useAuth()

  const {show: showSubTreeOfPerson} = useSubTreeModalStore()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()

  const {persons, updatePersons} = usePersonsStore()
  const personIndex = useMemo(
    () => persons.findIndex(({id}) => id === personId),
    [personId, persons]
  )
  const person = personIndex !== -1 ? persons[personIndex] : null
  // console.log('#### SubTreeButton', personId, person, persons)

  const handleOpenSubTreeClick = (event) => {
    console.log('### handleSubTreeClick.partner', person, currentUser?.is_premium)
    event.preventDefault()
    event.stopPropagation()

    console.log('### person?.id && treeId', person?.id, treeId)
    if (person?.id && treeId) {
      getPerson(person.id).then((response) => {
        console.log('### getPerson!', response)
        const personsClone = [...persons]

        const receivedPerson = response.data
        // console.log('### personsClone', personsClone)
        const indexOfPerson = personsClone.findIndex(({id}) => receivedPerson.id === id)
        if (indexOfPerson !== -1) {
          // @ts-ignore
          personsClone[indexOfPerson] = receivedPerson
          updatePersons(personsClone, true, () => {
            if (!receivedPerson.subtree && !currentUser?.is_premium) {
              showSubscriptionModal()
              event.preventDefault()
              event.stopPropagation()

              return
            }

            // console.log('### SHOW')
            showSubTreeOfPerson({personId: person.id, treeId})
          })
        }
      })
    }
  }

  if (!person) return null

  if (person?.is_subtree) {
    return (
      <OpenSubTreeButton
        $size={nodeSize}
        $avatarBorderWidth={avatarBorderWidthParsed * 2}
        onClick={handleOpenSubTreeClick}
      >
        <i className='ki-duotone ki-data'>
          <span className='path1' />
          <span className='path2' />
          <span className='path3' />
          <span className='path4' />
          <span className='path5' />
        </i>
      </OpenSubTreeButton>
    )
  }

  if (hover && editMode && !person.is_subtree) {
    return (
      <OpenSubTreeButton
        $size={nodeSize}
        $avatarBorderWidth={avatarBorderWidthParsed}
        onClick={handleOpenSubTreeClick}
      >
        <PlusIconWrapper>
          <KTIcon iconName='plus' className='fs-7' />
        </PlusIconWrapper>
        <i className='ki-duotone ki-data'>
          <span className='path1' />
          <span className='path2' />
          <span className='path3' />
          <span className='path4' />
          <span className='path5' />
        </i>
      </OpenSubTreeButton>
    )
  }

  return null
}

export default React.memo(SubTreeButton)

const OpenSubTreeButton = styled.div<{$size: number; $avatarBorderWidth: number}>`
  background-color: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;

  position: absolute;

  top: ${({$size, $avatarBorderWidth}) => $size / 10 - 30 / 2 + 2}px;
  right: ${({$size, $avatarBorderWidth}) => $size / 10 - 30 / 2 + 2}px;

  &:hover {
    opacity: 0.8;
  }

  display: flex;
  justify-content: center;
  align-items: center;
`

const PlusIconWrapper = styled.div`
  position: absolute;
  left: -6px;
  top: 8px;
  height: 15px;
  width: 15px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;

  i {
    font-size: 12px !important;
  }
`
