import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import styled from 'styled-components'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {getPerson, getPhotos, uploadImage} from '@api/requests'
import {useAuth} from '@modules/auth'

import useHostBasedUrl from '@hooks/useHostBasedUrl'
import usePersonAvatars from '@hooks/usePersonAvatars'

import {usePhotosStore} from '@store/photos'
import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import {usePersonsStore} from '@store/persons'

import CustomGallery from '@components/molecules/CustomGallery/CustomGallery'
import RelationsBlock from '@_metronic/partials/layout/sim-drawer/components/RelationsBlock'

import {PersonModel} from '@api/models'

import {GALLERY_IMAGE_TARGET_SIZES} from '@constants/imageUploading'
import {RelationType} from '@app/types'

const getPersonGalleryKey = (personId: number) => `sim_gallery_${personId}`

const SimDrawerContent = ({personId, readonly = false}) => {
  const intl = useIntl()
  const butOpenEditorRef = useRef<any>()
  const {selectPhoto, setActivePhoto, setPhotos: setGalleryPhotos} = usePhotosStore()

  const {persons, updatePersons} = usePersonsStore()

  const [loading, setLoading] = useState(false)

  const imageRef = useRef<HTMLInputElement | null>(null)
  const [person, setPerson] = useState<PersonModel | null>(null)
  const [photos, setPhotos] = useState([])
  const [fields, setFields] = useState([])
  const [genderPronoun, setGenderPronoun] = useState<string>()

  useEffect(() => {
    const foundPerson = persons.find(({id}) => id === personId)

    if (foundPerson) {
      getPhotos(getPersonGalleryKey(personId!)).then((r) => {
        // @ts-ignore
        setPhotos(r.data)
      })
      // @ts-ignore
      setFields(foundPerson.params ? foundPerson.params.fields : [])
      setGenderPronoun(foundPerson.params?.genderPronoun)

      setPerson(foundPerson)
    }
  }, [personId, persons])

  useEffect(() => {
    if (personId) {
      console.log('### SimDrawerContent.personId', personId)
      const foundPerson = persons.find(({id}) => id === personId)
      // if (foundPerson) {
      //   setPerson(foundPerson)
      //   // @ts-ignore
      //   setFields(foundPerson.params ? foundPerson.params.fields : [])
      //   setGenderPronoun(foundPerson.params?.genderPronoun)
      // }

      setLoading(true)

      getPhotos(getPersonGalleryKey(personId!)).then((r) => {
        // @ts-ignore
        setPhotos(r.data)
      })

      getPerson(personId)
        .then((response) => {
          console.log('### getPerson.response', response)
          const receivedPerson = response.data

          const refreshedPerson = {...foundPerson, ...receivedPerson}
          console.log('### refreshedPerson', refreshedPerson)

          const personsClone = [...persons]
          const foundPersonIndex = personsClone.findIndex(({id}) => id === refreshedPerson.id)

          if (foundPersonIndex === -1) {
            updatePersons([...personsClone, refreshedPerson], true)
          } else {
            personsClone[foundPersonIndex] = refreshedPerson
            updatePersons(personsClone, true)
          }

          // setPerson(refreshedPerson)
          // setFields(refreshedPerson.params ? refreshedPerson.params.fields : [])
          // setGenderPronoun(refreshedPerson.params?.genderPronoun)
        })
        .catch((error) => console.log('### error', error))
        .finally(() => {
          setLoading(false)
        })
    } else {
      setPhotos([])
      setPerson(null)
      setFields([])
    }
  }, [personId])

  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const clickToInput = (event) => {
    if (!currentUser?.is_premium) {
      showSubscriptionModal()
      event.preventDefault(true)
      event.stopPropagation()
      return
    }

    imageRef.current?.click()
  }
  const callBackForCrop = (file: Blob) => {
    let reader = new FileReader()
    reader.onload = function (e) {
      if (e?.target?.result) {
        uploadImage(file, getPersonGalleryKey(personId!)).then((response) => {
          console.log('### response', response)
          const photosCopy = photos ? [...photos] : []
          // @ts-ignore
          photosCopy.push(response.data)
          setPhotos(photosCopy)
        })
      }
    }
    reader.readAsDataURL(file)
  }
  function handleFiles(event) {
    if (!event.currentTarget.files?.length) return
    let file = event.currentTarget.files[0]
    selectPhoto(file, callBackForCrop, undefined, GALLERY_IMAGE_TARGET_SIZES)

    event.currentTarget.value = ''

    if (butOpenEditorRef?.current) butOpenEditorRef.current.click()
  }

  const avatars = usePersonAvatars(personId)
  const avatarUrl = useHostBasedUrl(person?.avatar?.url)

  // console.log('### person.description', person?.description)

  return (
    <div className='pt-5' style={{overflowX: 'hidden'}}>
      <button
        ref={butOpenEditorRef}
        style={{pointerEvents: 'none', opacity: 0, position: 'absolute'}}
        data-bs-toggle='modal'
        data-bs-target='#kt_modal_edit_photo'
      />
      <div className='d-flex flex-column text-center mb-9 px-9'>
        <div
          data-bs-toggle={avatars.length > 0 ? 'modal' : ''}
          data-bs-target={'#kt_modal_show_photos'}
          className={clsx('symbol symbol-150px mb-4', {'cursor-pointer': avatars.length > 0})}
          onClick={() => {
            if (avatars.length > 0) {
              // @ts-ignore
              setGalleryPhotos(avatars)
              const currentAvatarIndex = avatars.findIndex(({url}) => url === avatarUrl)
              setActivePhoto(currentAvatarIndex >= 0 ? currentAvatarIndex : 0)
            }
          }}
        >
          {/* @ts-ignore*/}
          {avatarUrl ? (
            <img src={avatarUrl} className='' alt='' style={{objectFit: 'cover'}} />
          ) : (
            <img
              src='https://my-engine.ru/modules/users/avatar.png'
              alt=''
              style={{objectFit: 'cover'}}
            />
          )}
          {loading ? (
            <div className='d-flex flex-fill justify-content-center'>
              <span className='indicator-progress mt-5' style={{display: 'block'}}>
                {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            </div>
          ) : null}
        </div>

        <div className='text-center'>
          <span className='text-gray-800 fw-bold text-hover-primary fs-1'>
            {person && `${person.first_name} ${person.last_name}`}
          </span>

          <span className='text-muted d-block fw-semibold'>
            {person && intl.formatMessage({id: person.is_alive ? 'PERSON.ALIVE' : 'PERSON.DEAD'})}
          </span>
        </div>
      </div>

      {!readonly || (readonly && photos?.length > 0) ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'GALLERY.TITLE'})}
              </span>
            </h3>

            <div className='card-toolbar'>
              {!readonly ? (
                <span onClick={clickToInput} className='btn btn-sm btn-light'>
                  {intl.formatMessage({id: 'ADD.PHOTO'})}
                </span>
              ) : null}
              <input
                ref={imageRef}
                style={{position: 'absolute', pointerEvents: 'none', opacity: 0}}
                type='file'
                name='avatar'
                accept='.png, .jpg, .jpeg'
                onChange={handleFiles}
              />
            </div>
          </div>

          {photos?.length > 0 ? <CustomGallery readonly={readonly} photos={photos} /> : null}
        </div>
      ) : null}

      {person ? (
        <RelationsBlock relationType={RelationType.parent} readonly={readonly} person={person} />
      ) : null}
      {person && person?.type !== 1 ? (
        <RelationsBlock relationType={RelationType.pet} readonly={readonly} person={person} />
      ) : null}
      {person &&
      (person.type !== null ||
        person.gender !== null ||
        person.other_gender ||
        person.species ||
        person.character_traits ||
        person.life_goals ||
        fields?.length ||
        genderPronoun ||
        person.description) ? (
        <div className='card border-0 shadow-none rounded-0 w-100'>
          <div className='card-header p-0 mb-4'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bold text-gray-900'>
                {intl.formatMessage({id: 'INFORMATION'})}
              </span>
            </h3>
          </div>
        </div>
      ) : null}

      {person && (
        <div>
          <div className='row mb-7 mt-7'>
            <div className='col-lg-4 fw-semibold text-muted  fs-6'>
              {intl.formatMessage({id: 'SIM.TYPE'})}
            </div>

            <div className='col-lg-8 fv-row fw-semibold text-gray-800 fs-6'>
              {person.type === 1
                ? intl.formatMessage({id: 'SIM.TYPE.PET'})
                : intl.formatMessage({id: 'SIM.TYPE.PERSON'})}
            </div>
          </div>

          {person.gender !== 2 && person.gender !== null && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SIM.GENDER'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                {person.gender === 0
                  ? intl.formatMessage({id: 'SELECT.GENDER.MALE'})
                  : intl.formatMessage({id: 'SELECT.GENDER.FEMALE'})}
              </div>
            </div>
          )}
          {person.gender === 2 && person.other_gender ? (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SIM.GENDER'})}
              </div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{person.other_gender}</div>
            </div>
          ) : null}
          {person.gender === 2 && genderPronoun ? (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SELECT.GENDER.PRONOUNS'})}
              </div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{genderPronoun}</div>
            </div>
          ) : null}
          {!!person.species && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'SIM.RACE'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{person.species}</div>
            </div>
          )}
          {!!person.character_traits && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'PERSON.PERS'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>
                {person.character_traits}
              </div>
            </div>
          )}
          {!!person.life_goals && (
            <div className='row mb-7'>
              <div className='col-lg-4 fw-semibold text-muted  fs-6'>
                {intl.formatMessage({id: 'PERSON.GOALS'})}
              </div>

              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{person?.life_goals}</div>
            </div>
          )}
          {fields?.map((x, k) => (
            <div className='row mb-7' key={k}>
              <div className='col-lg-4 fw-semibold text-muted fs-6'>{x[0]}</div>
              <div className='col-lg-8 fw-semibold text-gray-800 fs-6'>{x[1]}</div>
            </div>
          ))}

          {person.description ? (
            <Description
              className='lead ql-editor'
              dangerouslySetInnerHTML={{__html: person.description}}
            />
          ) : null}
        </div>
      )}
    </div>
  )
}

export {SimDrawerContent}

const Description = styled.div`
  & blockquote {
    border-left: 4px solid #ccc;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 16px;
  }
`
