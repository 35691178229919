import React, {useEffect, useMemo, useState} from 'react'
import {useLocation} from 'react-router'
import styled from 'styled-components'

import {useTreesStore} from '@store/trees'
import {usePersonsStore} from '@store/persons'

import {getPublicTree} from '@api/requests'
import {PersonModel} from '@api/models'

import {
  SimDrawer,
  SimDrawerStorePayload,
  useSimDrawerStore,
} from '@_metronic/partials/layout/sim-drawer/SimDrawer'
import {PageDataProvider} from '@_metronic/layout/core'
import {ToolbarWrapper} from '@_metronic/layout/components/toolbar'
import {ViewPhotosDrawer} from '@_metronic/partials/layout/viewphotos-drawer/ViewPhotosDrawer'
import {ViewTreeDrawer} from '@_metronic/partials/layout/viewtree-drawer/ViewTreeDrawer'
import {ViewArticleDrawer} from '@_metronic/partials/layout/viewarticle-drawer/ViewArticleDrawer'
import GalleryDrawerMessenger from '@pages/Gallery/components/GalleryDrawerMessenger'

import Tree from '@components/organisms/Tree'
import CommentsAndLikes from '@components/atoms/CommentsAndLikes'
import useShareTreeLink, {
  DIARY_ARTICLE_ID_SHARE_LINK_PARAM,
  PERSON_ID_SHARE_LINK_PARAM,
} from '@hooks/useShareTreeLink'
import SubTreeModal from '@components/molecules/SubTreeModal'
import {useSubTreeModalStore} from '@components/molecules/SubTreeModal/SubTreeModal'

const PublicTree = () => {
  const {trees, activeTreeId, addTree} = useTreesStore()
  const {updatePersons} = usePersonsStore()
  const tree = useMemo(() => {
    if (activeTreeId && trees[activeTreeId]) return trees[activeTreeId]
  }, [trees, activeTreeId])

  const [persons, setPersons] = useState<PersonModel[]>()
  // console.log('')

  const {pathname} = useLocation()
  const token = pathname.split('/').reverse()[0]
  const [refToken, setRefToken] = useState<string>()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (token) {
      getPublicTree(token)
        .then((response) => {
          console.log('### getPublicTree.response', response)
          const {tree, persons, author} = response.data
          addTree(tree)
          setPersons(persons)
          setRefToken(author?.ref_token)

          updatePersons(persons, true)
        })
        .catch((error) => {
          console.log('### error', error)
        })
        .finally(() => setLoaded(true))
    }
  }, [token])

  const queryString = useShareTreeLink({refToken}, true)
  useEffect(() => {
    const containToken = window.location.href.includes('ref=')
    if (refToken && !containToken) {
      window.history.pushState(null, '', queryString)
    }
  }, [refToken])

  const {opened: subTreeModalOpened} = useSubTreeModalStore()
  const {show: showSimDrawer} = useSimDrawerStore()
  const location = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)

    const sharedPersonId = searchParams.get(PERSON_ID_SHARE_LINK_PARAM)
    if (sharedPersonId) {
      const sharedDiaryArticleId = searchParams.get(DIARY_ARTICLE_ID_SHARE_LINK_PARAM)

      let options: SimDrawerStorePayload = {allowSharing: !subTreeModalOpened}

      if (sharedDiaryArticleId) {
        options.articleId = sharedDiaryArticleId
      }
      showSimDrawer(parseInt(sharedPersonId), options)
    }
  }, [location])

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                {tree && persons?.length ? (
                  <Wrapper>
                    <Toolbar tree={tree} editMode={false} refToken={refToken} readonly>
                      {/*@ts-ignore*/}
                      {tree.publish ? (
                        <CommentsAndLikes treePublish={tree.publish} hideFavorite />
                      ) : null}
                    </Toolbar>

                    <SubTreeModal readonly editMode={false}>
                      <Tree
                        treeId={tree?.id}
                        tree={tree}
                        persons={persons}
                        editMode={false}
                        readonly
                      />
                    </SubTreeModal>
                  </Wrapper>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loaded ? (
        <>
          <ViewTreeDrawer readonly />
          <SimDrawer readonly refToken={refToken} />
          <ViewPhotosDrawer />
          <GalleryDrawerMessenger refToken={refToken} />
          <ViewArticleDrawer refToken={refToken} />
        </>
      ) : null}
    </PageDataProvider>
  )
}

export default React.memo(PublicTree)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Toolbar = styled(ToolbarWrapper)`
  padding-left: 10px !important;
  padding-top: 10px !important;
`
