import React from 'react'
import styled from 'styled-components'
import {
  MessagesInfo,
  MessageType,
} from '@pages/Gallery/components/GalleryDrawerMessenger/GalleryDrawerMessenger'

type CitationProps = {citation: MessageType; author: any; onReset?: () => void}
const Citation = ({citation, author, onReset}: CitationProps) => {
  return (
    <Wrapper>
      <AuthorName>
        {author.first_name}
        {onReset ? (
          <span className='text-danger ms-2 cursor-pointer' onClick={onReset}>
            Удалить
          </span>
        ) : null}
      </AuthorName>
      <span>{citation.message}</span>
    </Wrapper>
  )
}

export default React.memo(Citation)

const Wrapper = styled.div`
  border-radius: 3px;
  background-color: #060e3a14;
  padding: 4px;
  border-left: 3px #4caf50 solid;
  margin-bottom: 5px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`

const AuthorName = styled.span`
  color: #4caf50;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
`
