/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import {KTIcon} from '../../../helpers'
import {useAuth} from '@modules/auth'

import {UserProfileDrawerContent} from './UserProfileDrawerContent'
import {updateProfile} from '@api/requests'

const MySwal = withReactContent(Swal)

export type UserProfileValues = {
  avatar?: {id: number; url: string} | null
  first_name?: string
  password?: string
  passwordConfirm?: string
}

const UserProfileDrawer = () => {
  const closeButtonRef = useRef<HTMLButtonElement>(null)
  const intl = useIntl()
  const {currentUser, setCurrentUser, logout} = useAuth()

  const [values, setValues] = useState<UserProfileValues>({
    avatar: currentUser?.avatar,
    first_name: currentUser?.first_name,
  })

  useEffect(() => {
    setValues((state) => ({
      ...state,
      avatar: currentUser?.avatar,
      first_name: currentUser?.first_name,
    }))
  }, [currentUser])

  const handleValuesChange = (data) => {
    setValues(data)
  }

  const handleSavePress = () => {
    const data: {avatar_id?: number; first_name?: string; password?: string} = {}

    if (values.avatar?.id && currentUser?.avatar?.id !== values.avatar?.id) {
      data.avatar_id = values.avatar?.id
    }

    if (!values.first_name) {
      MySwal.fire({
        position: 'center',
        icon: 'error',
        title: ' Имя не может быть пустым',
        showConfirmButton: false,
        timer: 1500,
        heightAuto: false,
      })
      return
    }

    if (values.password) {
      if (values.password !== values.passwordConfirm) {
        MySwal.fire({
          position: 'center',
          icon: 'error',
          title: 'Пароли не совпадают',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
        return
      }

      if (values.password.length < 8) {
        MySwal.fire({
          position: 'center',
          icon: 'error',
          title: 'Пароли слишком короткий',
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        })
        return
      }

      data.password = values.password
    }

    if (values.first_name && currentUser?.first_name !== values.first_name) {
      data.first_name = values.first_name
    }

    if (Object.keys(data).length) {
      updateProfile(data).then((response) => {
        MySwal.fire({
          position: 'center',
          icon: 'success',
          title: intl.formatMessage({id: 'MSG.PROFILE.UPDATED'}),
          showConfirmButton: false,
          timer: 1500,
          heightAuto: false,
        }).then(() => {
          setCurrentUser(response.data)
          closeButtonRef.current?.click()

          if (data.password) {
            setValues((state) => ({
              ...state,
              password: '',
              passwordConfirm: '',
            }))
            logout()
          }
        })
      })
    } else {
      closeButtonRef.current?.click()
    }
  }

  return (
    <div
      id='kt_userprofile'
      className='bg-body'
      data-kt-drawer='true'
      data-kt-drawer-name='userprofile'
      data-kt-drawer-activate='true'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'350px', 'md': '525px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_userprofile_toggle'
      data-kt-drawer-close='#kt_userprofile_close'
    >
      {/* begin::Card */}
      <div className='card shadow-none rounded-0 w-100'>
        {/* begin::Header */}
        <div className='card-header' id='kt_help_header'>
          <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'USER.EDIT_PROFILE'})}</h5>

          <div className='card-toolbar'>
            <button
              type='button'
              className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
              id='kt_userprofile_close'
            >
              <KTIcon iconName='cross' className='fs-2' />
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <UserProfileDrawerContent values={values} onValuesChange={handleValuesChange} />
        {/* end::Body */}

        {/* begin::Footer */}
        <div className='card-footer py-5 text-center' id='kt_activities_footer'>
          <div className='d-flex justify-content-end'>
            <button
              className='btn btn-secondary d-flex justify-content-end me-3'
              id='kt_userprofile_close'
              ref={closeButtonRef}
            >
              {intl.formatMessage({id: 'ACTIONS.CLOSE'})}
            </button>
            <button
              className='btn btn-success d-flex justify-content-end'
              onClick={handleSavePress}
            >
              {intl.formatMessage({id: 'ACTIONS.SAVE'})}
            </button>
          </div>
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Card */}
    </div>
  )
}

export {UserProfileDrawer}
