/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo, useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import Swal from 'sweetalert2'

import {
  MessagesInfo,
  MessageType,
  useGalleryMessengerModalStore,
} from '@pages/Gallery/components/GalleryDrawerMessenger/GalleryDrawerMessenger'

import {createComment, deleteComment, getComments} from '@api/requests'

import {useAuth} from '@modules/auth'

import Message from './Message'
import MessageInput from './MessageInput'
import Citation from '@pages/Gallery/components/GalleryDrawerMessenger/components/Citation'

type Props = {
  visible: boolean
  refToken?: string
}

const GalleryMessenger: FC<Props> = ({visible, refToken}) => {
  const {currentUser} = useAuth()
  const {galleryTreePublish, sentCallback} = useGalleryMessengerModalStore()
  const ownTree = currentUser?.id === galleryTreePublish?.author.id

  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<MessagesInfo | null>(null)

  const messagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    //@ts-ignore
    messagesRef.current?.scrollTo({left: 0, top: messagesRef.current?.scrollHeight})
  }, [messages])

  const loadMessages = () => {
    if (galleryTreePublish?.token) {
      setLoading(true)
      getComments(galleryTreePublish.token)
        .then((response) => {
          setMessages(response.data)
        })
        .catch((error) => {
          console.log('### getComments.error', error)
        })
        .finally(() => setLoading(false))
    }
  }

  const [citation, setCitation] = useState<MessageType>()
  const selectCitation = (commentId: number) => {
    setCitation(messages?.comments.find(({id}) => id === commentId))
  }

  const unselectCitation = () => {
    setCitation(undefined)
  }

  useEffect(() => {
    if (visible) {
      setMessages(null)
      loadMessages()
    } else {
      unselectCitation()
    }
  }, [visible, galleryTreePublish])

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => loadMessages(), 15000)

      return () => clearInterval(interval)
    }
  }, [visible])

  const messagesMap = useMemo(() => {
    return (messages?.comments || []).reduce((allCommentsObj, comment) => {
      allCommentsObj[comment.id] = comment

      return allCommentsObj
    }, {})
  }, [messages])

  const sendMessage = (text: string) => {
    if (galleryTreePublish?.token) {
      createComment(galleryTreePublish.token, text, citation?.id)
        .then((response) => {
          const comments = [...(messages?.comments || []), response.data as MessageType]
          sentCallback?.(comments.length)
          setMessages({authors: messages?.authors || {}, comments})
          unselectCitation()
        })
        .catch((error) => {
          console.log('### createComment.error', error)
        })
    }
  }

  const intl = useIntl()
  const deleteMessage = (id: number) => {
    // ASK CONFIRMATION
    Swal.fire({
      heightAuto: false,
      title: intl.formatMessage({id: 'A.YOU.SURE'}),
      text: intl.formatMessage({id: 'TREE.CHAT.MESSAGE_DELETION'}),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#50cd89',
      cancelButtonText: intl.formatMessage({id: 'CANCEL'}),
      confirmButtonText: intl.formatMessage({id: 'CONFIRM'}),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteComment(id).then((response) => {
          setMessages({
            authors: messages?.authors || {},
            comments: messages?.comments.filter((comment) => comment.id !== id) || [],
          })
        })
      }
    })
  }

  return (
    <>
      <div className='card-body hover-scroll-overlay-y h-400px pt-5' ref={messagesRef}>
        {loading ? (
          <div className='d-flex flex-fill justify-content-center'>
            <span className='indicator-progress mt-5' style={{display: 'block'}}>
              {intl.formatMessage({id: 'ACTIONS.WAIT'})}{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          </div>
        ) : (
          messages?.comments?.map((message) => (
            <Message
              key={message.id}
              authors={messages?.authors}
              message={message}
              citation={message?.comment_id ? messagesMap[message?.comment_id] : undefined}
              ownTree={ownTree}
              onDelete={deleteMessage}
              onSelect={selectCitation}
            />
          ))
        )}
      </div>

      {citation ? (
        <Citation
          citation={citation}
          author={messages?.authors[citation.user_id]}
          onReset={unselectCitation}
        />
      ) : null}

      <div className='card-footer py-5 text-center d-flex' id='kt_activities_footer'>
        <MessageInput sendMessage={sendMessage} refToken={refToken} />
      </div>
    </>
  )
}

export default GalleryMessenger
