import React, {useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import InnerHTML from 'dangerously-set-html-content'

import {PageDataProvider} from './core'
import {reInitMenu} from '../helpers'

import {useIsNonRUCountryCheck} from '@hooks/useCountryCheck'

import {HeaderWrapper} from './components/header'
import {RightToolbar} from '../partials/layout/RightToolbar'
import {ScrollTop} from './components/scroll-top'
import {Content} from './components/content'
import {FooterWrapper} from './components/footer'
import {Sidebar} from './components/sidebar'
import {ActivityDrawer, DrawerMessenger, InviteUsers, UpgradePlan} from '../partials'
import SubscriptionModal from '@components/molecules/SubscriptionModal'
import CurrentSubscriptionModal from '@components/molecules/CurrentSubscriptionModal'
import GalleryDrawerMessenger from '@pages/Gallery/components/GalleryDrawerMessenger'
import PersonsModal from '@components/molecules/PersonsModal'

const FACEBOOK_HTML = `
  <script>
    setTimeout(() => {
			if (fbq) {
				fbq('track', 'Lead');
			}
    }, 1000)
  </script>
`

const MasterLayout = () => {
  const location = useLocation()
  useEffect(() => {
    reInitMenu()
  }, [location.key])

  const notRussia = useIsNonRUCountryCheck()

  return (
    <PageDataProvider>
      <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
        <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
          <HeaderWrapper />
          <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
            <Sidebar />
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
              <div className='d-flex flex-column flex-column-fluid'>
                <Content>
                  <Outlet />
                </Content>
              </div>
              <FooterWrapper />
            </div>
          </div>
        </div>
      </div>

      {/* begin:: Drawers */}
      <ActivityDrawer />
      <RightToolbar />
      <DrawerMessenger />
      <GalleryDrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      <UpgradePlan />
      <SubscriptionModal />
      <CurrentSubscriptionModal />
      <PersonsModal />

      {/* end:: Modals */}
      <ScrollTop />
      <div>{notRussia ? <InnerHTML html={FACEBOOK_HTML} /> : null}</div>
    </PageDataProvider>
  )
}

export {MasterLayout}
