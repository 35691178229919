/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef, useState} from 'react'
import {useIntl} from 'react-intl'
import {create} from 'zustand'
import styled from 'styled-components'
import clsx from 'clsx'
import {useAuth} from '@modules/auth'

import {useSubscriptionModalStore} from '@components/molecules/SubscriptionModal/SubscriptionModal'
import useIsFirstOrImportedTree from '@hooks/useIsFirstOrImportedTree'

import {ViewTreeDrawerContent} from './ViewTreeDrawerContent'
import {KTIcon} from '../../../helpers'

import {TreeModel} from '@api/models'
// import SimDrawerAvatars from '@_metronic/partials/layout/sim-drawer/SimDrawerAvatars'
// import ViewTreeDrawerAvatars from '@_metronic/partials/layout/viewtree-drawer/ViewTreeDrawerAvatars'

enum Tabs {
  info = 'info',
  avatars = 'avatars',
}
const DEFAULT_TAB = Tabs.info

type ViewTreeDrawerStore = {
  opened: boolean
  treeId: TreeModel['id'] | null
  show: (tree: TreeModel['id']) => void
  hide: () => void
}

export const useViewTreeDrawerStore = create<ViewTreeDrawerStore>((set) => ({
  opened: false,
  treeId: null,
  show: (treeId) => set((state) => ({...state, opened: true, treeId})),
  hide: () => set((state) => ({...state, opened: false, treeId: null})),
}))

const ViewTreeDrawer = ({readonly = false}) => {
  const intl = useIntl()

  const {treeId, opened, hide} = useViewTreeDrawerStore()

  const butRef = useRef<any>()
  const {currentUser} = useAuth()
  const {show: showSubscriptionModal} = useSubscriptionModalStore()
  const isFirstOrImportedTree = useIsFirstOrImportedTree(treeId)
  const openEdit = (event) => {
    if (!currentUser?.is_premium && !isFirstOrImportedTree) {
      showSubscriptionModal()
      event.preventDefault()
      event.stopPropagation()
    } else if (butRef && butRef.current) {
      //@ts-ignore
      butRef.current.click()
    }
  }

  // const [tab, setTab] = useState(DEFAULT_TAB)

  return (
    <>
      {opened ? <Overlay onClick={hide} /> : null}
      <Wrapper className='bg-body' $hidden={!opened}>
        {treeId ? (
          <button ref={butRef} style={{position: 'absolute', opacity: 0, pointerEvents: 'none'}} />
        ) : null}

        {/* begin::Card */}
        <div className='card shadow-none rounded-0 w-100'>
          {/* begin::Header */}
          <div className='card-header' id='kt_help_header'>
            <h5 className='card-title fw-bold'>{intl.formatMessage({id: 'TREE.CARD.TITLE'})}</h5>

            <div className='card-toolbar'>
              <button
                type='button'
                className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                onClick={hide}
              >
                <KTIcon iconName='cross' className='fs-2' />
              </button>
            </div>
          </div>

          {/* begin::Header */}
          {/*<div className='card-header' id='kt_help_header'>
            <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0'>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.info})}
                  onClick={() => setTab(Tabs.info)}
                >
                  {intl.formatMessage({id: 'SIM.VIEW.TAB_INFO'})}
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className={clsx(`nav-link cursor-pointer`, {active: tab === Tabs.avatars})}
                  onClick={() => setTab(Tabs.avatars)}
                >
                  {intl.formatMessage({id: 'SIM.VIEW.TAB_AVATARS'})}
                </a>
              </li>
            </ul>
          </div>*/}
          {/* end::Header */}

          {/* begin::Body */}
          <ViewTreeDrawerContent treeId={treeId} readonly={readonly} />

          {/* end::Body */}
          {/* begin::Footer */}
          {!readonly ? (
            <div className='card-footer py-5 text-center' id='kt_activities_footer'>
              <div className='d-flex justify-content-end'>
                <button
                  className='btn btn-secondary d-flex justify-content-end me-3'
                  onClick={hide}
                >
                  {intl.formatMessage({id: 'TREE.ADD.CLOSE'})}
                </button>
                <button
                  id={'kt_edittree_toggle'}
                  className='btn btn-success d-flex justify-content-end'
                  onClick={openEdit}
                >
                  {intl.formatMessage({id: 'EDIT'})}
                </button>
              </div>
            </div>
          ) : null}
          {/* end::Footer */}
        </div>
        {/* end::Card */}
      </Wrapper>
    </>
  )
}

export {ViewTreeDrawer}

const Wrapper = styled.div<{$hidden: boolean}>`
  display: ${({$hidden}) => ($hidden ? 'none' : 'flex')};

  position: fixed;
  top: 0;
  right: 0;

  width: 525px;
  height: 100%;

  z-index: 106;

  @media (max-width: 800px) {
    width: 350px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.25);

  z-index: 105;
`
