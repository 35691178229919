export const NODE_WIDTH = 70
export const NODE_HEIGHT = 120

export const LINE_FATNESS = 3

export enum RelationsEnum {
  partner = 'partner', // пара
  married = 'married', // женаты
  divorced = 'divorced', // в разводе
  friends = 'friends', // друзья
  engaged = 'engaged', // помолвлены
  formerPartner = 'formerPartner', // бывшие партнеры
  siblings = 'siblings', // брат/сестра
  widow = 'widow', // вдова/вдовец
  flirt = 'flirt', // флирт
  other = 'other', // другое
  ufo = 'ufo', // другое
}
