import React, {CSSProperties, useState} from 'react'
import ItemsCarousel from 'react-items-carousel'
import styled from 'styled-components'

import {usePhotosStore} from '@store/photos'

import Photo from './components/Photo'

const defaultStyle: CSSProperties = {
  width: '100%',
  height: 150,
  borderRadius: 12,
  cursor: 'pointer',
  objectFit: 'cover',
}
type CustomGalleryProps = {
  photos: {id: number; url: string}[]
  photoStyle?: CSSProperties
  currentPhoto?: string
  readonly?: boolean
}
const CustomGallery = ({
  photos: rawPhotos,
  photoStyle = defaultStyle,
  currentPhoto,
  readonly = false,
}: CustomGalleryProps) => {
  console.log('### rawPhotos', rawPhotos)
  const {setPhotos, setActivePhoto} = usePhotosStore()
  const [deletedPhotoIds, setDeletedPhotoIds] = useState<number[]>([])
  const handlePhotoDelete = (photoId: number) =>
    setDeletedPhotoIds((state) => [...(state || []), photoId])

  const [activeSlide, setActiveSlide] = useState(0)

  const photos = rawPhotos.filter((photo) => !deletedPhotoIds.includes(photo.id))

  return (
    <div className='card-body p-5'>
      <ItemsCarousel
        // Placeholder configurations
        infiniteLoop
        enablePlaceholder
        numberOfPlaceholderItems={5}
        minimumPlaceholderTime={1000}
        placeholderItem={<Placeholder>Placeholder</Placeholder>}
        // Carousel configurations
        numberOfCards={2}
        gutter={12}
        showSlither={false}
        firstAndLastGutter={false}
        freeScrolling={false}
        // Active item configurations
        activePosition={'center'}
        chevronWidth={50}
        activeItemIndex={activeSlide}
        alwaysShowChevrons
        requestToChangeActive={(value) => setActiveSlide(value)}
        outsideChevron
        rightChevron={<LeftChevron className='ki-duotone ki-right fs-3x' />}
        leftChevron={<RightChevron className='ki-duotone ki-left fs-3x' />}
      >
        {photos?.map((photo, index) => (
          // @ts-ignore
          <Photo
            key={index}
            //@ts-ignore
            style={photoStyle}
            readonly={readonly || currentPhoto === photo.url}
            // src={photo.url}
            photo={photo}
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_show_photos'
            onDelete={handlePhotoDelete}
            onClick={() => {
              // @ts-ignore
              setPhotos(photos)
              setActivePhoto(index)
            }}
          />
        ))}
      </ItemsCarousel>
    </div>
  )
}
export default CustomGallery

const Placeholder = styled.div`
  height: 200px;
  background-color: #900;
`

const LeftChevron = styled.i`
  position: absolute;
  left: -12px;
`

const RightChevron = styled.i`
  position: absolute;
  right: -12px;
`
